import React from "react";
import "./styles/History.scss";
import HistoryCardType1 from "../../../components/HistoryCardType1";
import HistoryCardType2 from "../../../components/HistoryCardType2";
import HistoryContent from "./HistoryContent";

const History = () => {
  const list_01 = ["(주) 아키테코 그룹 창립", "(주) 지오디엔티 설립", "기업부설연구소 인정 ", "특허등록(1)", "벤처기업인증", "기업부설연구소 인정", "한국생태환경건축학회 우수논문발표상", "기술보증기금 탄소감축업체 지원"];
  const list_02 = [
    "2023 혁신한국인 여성친화부문 대상",
    "2023 혁신한국인 ESG경영부문 대상",
    "2023 대한민국 혁신기업 탄소중립 부문 대상",
    "제주지사 설립",
    "특허등록(2)",
    "특허출원(3)",
    "친환경기술 녹색소비부문 정부산업포상",
    "한국생태환경건축학회 기술상",
  ];
  const list_03 = ["KICT (건설기술원) 패밀리기업 지정", "특허출원 8건 출원중", "에코스타트업 선정", "자발적탄소시장협회 (VCMC)기업"];
  const list_04 = [
    { sub: "2022년", data: ["(주)아키테코그룹 설립", "(주)지오디엔티 설립", "기업부설연구소 인정"] },
    { sub: "본사 / 제주도 지사", data: ["서울특별시 강남구 삼성로 575", "제주특별자치도 서귀포시 표선면 표선백사로 58번길 111"] },
    { sub: "대표자", data: ["고정림", "정규직원 40명"] },
  ];
  const list_05 = [
    "중소기업확인서",
    "ISO 9001:2015 품질경영시스템 인증서",
    "ISO 14001:2015 환경격영시스템 인증서",
    "ISO 45001:2018 안전보건경영시스템 인증서",
    "여성기업 확인서",
    "산업디자인전문회사 신고",
    "벤처기업확인서",
    "그린리모델링 사업자 등록 (지오디엔티)",
    "공공디자인전문회사 등록 (지오디엔티)",
    "ESG경영 성과 확인서",
    "창업기업확인서",
  ];
  return (
    <>
      <div id="history_wrap">
        <div className="title_wrap">
          <h2>History</h2>
          <div className="des">연혁</div>
        </div>
        <HistoryContent />
        {/* <HistoryCardType1 path="/imgs/history_card_01.png" title="2022년 비지니스 시작" list={list_01} />
        <HistoryCardType1 path="/imgs/history_card_02.png" title="2023년 특허등록/출원" list={list_02} />
        <HistoryCardType1 path="/imgs/history_card_03.png" title="2024년 에코스타트업" list={list_03} />
        <HistoryCardType2 path="/imgs/history_card_04.png" title="현황" list={list_04} />
        <HistoryCardType1 path="/imgs/history_card_05.png" title="인증" list={list_05} /> */}
      </div>
    </>
  );
};

export default History;
