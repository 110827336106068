import React from "react";
import "./styles/CeoContent.scss";

const CeoContent = () => {
  const text = `EU와 미국 등 국제적으로 가치사슬상 배출량(Scope 3) 고시를 의무화하는 등 전 세계 탄소중립 정책확산에 따라, 우리나라도 이행계획을 마련하고 있습니다.

2030 국가 온실가스 40% 감축 달성(2030 NDC)을 목표로 탄소중립 녹색성장 기본계획 및 세부 방안을 수립하였습니다. 
국내 건설업 온실가스 배출량은 ‘19년 기준 12%로 추정되고 있어 건설 건축에서의 탄소중립은 매우 시급한 당연 과제이며, 온실가스가 핵심 부분으로 포함된 ESG 경영이 확대되어 대기업뿐만 아니라 Supply Chain 상의 기업 및 글로벌기업 등 
산업 전반에 큰 영향을 끼치고 있습니다.

(주)아키테코그룹은 지구환경을 지키는 탄소중립 친환경 건축의 구현을 비롯하여 다양한 넷제로 솔루션 제공을 위해
선도적인 스마트 그린 ESG 플랫폼 기술을 리드하는 엔지니어링 전문 그룹입니다.

㈜아키테코그룹은 친환경 요소 적용을 위한 법적 요구조건, 다양한 실내외 영향인자에 대한 상관성 분석, 다수의 글로벌 기준 프로젝트 경험을 통해 얻은 노하우와 솔루션을 바탕으로 스마트 그린 탄소중립 기반 ESG 플랫폼을 구축하여 이 분야의 변화와 혁신을 이루기 위해 노력하고자 합니다.

지오(GeO)는 당사의 브랜드이며, 디자인과 테크놀로지를 융복합하는 지오디엔티와 지오R&D센터를 필두로 4차 산업을
리드하는 다양한 업역을 확장하고 있습니다. 당사는 기후 변화 대응과 관련하여 신설되는 글로벌 환경규제와 캠페인, 제로에너지를 목표로 하는 국가 방향성에 맞추어서 탄소 배출량 감축 및 탄소중립을 구현하는 스마트 그린 기술을 선도하기 위해 
최선을 다할 것입니다.

`;
  const card_text = [
    `대표이사 주요 이력`,
    `M, Arch.  /공학박사수료 / 경영학 석 박사
LEED AP (미국 친환경건축 전문가)
BREEAM ASSESSOR (영국 친환경건축 평가사)
GSAS (중동 친환경건축 전문가)`,
    `ESG 그린리모델링 자문위원
(국토부 국토안전관리원 건설산업연구원 환경공단)
매경이코노미 ESG 경영 칼럼리스트
중앙대 ESG 최고위과정 대표 강사
서울시 기후변화기금운용 심의위원
강동구, 부천시, 광명시 경관심의위원
서울시 건축사회 자문위원 (LCA)
(전)연세대, 중앙대, 인하대 외래교수 역임
친환경 분야 ${(<span className="fc_y">국내외 다수의 프로젝트들을 직접 총괄 수행 카타르 지사장</span>)}
재임 시 에너지관리공단을 통한 중동지역
${(<span className="fc_y">다수의 정부 기관 및 학술 단체, 협회</span>)}에서 ESG 자문위원 이사 활동
한국 영국 ‘넷제로 최적화 플랫폼 구축 ${(<span className="fc_y">국제 연구과제</span>)}’ 선정
${(<span className="fc_y">에너지효율등급 수출 등</span>)}`,
    `특허 등록 : ESG 기반 스마트 그린 건축 탄소중립 플랫폼
출원번호 : 10-2022-0144877
특허등록 : 교대 운전이 가능한 지중 열교환기
출원번호 : 10-2014-0138542

특허출원 : 웰인증 기반의 AI 통합관리시스템
출원번호 : 10-2023-0049285 / 공동발명자
특허출원 : 통신 기반의 공기질 통합제어 시스템
출원번호 : 10-2023-0049286 /공동발명자
`,
    `연세 미래 여성 100인 선정
한국그린빌딩협의회, 한국녹색건축기술협회 위원장
한국생태환경건축학회, 한국실내환경학회 이사
서울시청, 제주도청, 기타도시 녹색건축물/환경 자문위원
2023 혁신한국인 & POWER KOREA 대상 (ESG/기술혁신부분)
2023 혁신한국인 & POWER KOREA 대상 (여성 친화 기업 부문)
2023 대한민국 혁신인물 / 기업 / 브랜드 대상 (탄소중립 건축문화)
2023 친환경 기술진흥 및 소비촉진 유공자 ‘산업포장’ 수상
`,
    `연세대학교 공학사, 건축공학과 전공
Cornell University(미) 도시·건축대학원 석사
연세대학교 대학원 공학박사수료, 건축공학과(에너지) 전공
서울대학교 환경대학원 도시환경 최고위과정24기 수료
고려대학교-KEDGE(프) 경영대학원 석사, 매니지먼트 전공
고려대학교-KEDGE(프) 경영대학원 박사, 마케팅 전공
연세대학교 언론홍보대학원 언론홍보 최고위과정 46기 수료
`,
  ];

  return (
    <>
      <div id="ceo_content_wrap">
        <div className="title_wrap">
          <h2>CEO’s Message</h2>
          <div className="des">인사말</div>
        </div>
        <div className="contents">
          <div className="bg_img_box">
            <img src="/imgs/bg_ceo.png" alt="ceo_background" />
          </div>
          <div className="content">
            <div className="left">
              <div className="upper">
                <div className="img_box">
                  <img src="/imgs/ceo_content_medal_04.png" alt="medal" />
                </div>
              </div>
              <div className="under">
                <div className="img_box">
                  <img src="/imgs/ceo_content_medal_01.png" alt="medal" />
                </div>
                <div className="img_box">
                  <img src="/imgs/ceo_content_medal_02.png" alt="medal" />
                </div>
                <div className="img_box">
                  <img src="/imgs/ceo_content_medal_03.png" alt="medal" />
                </div>
              </div>
            </div>
            <div className="right">
              <div className="text_wrap">{text}</div>
              <div className="card_wrap">
                <div className="card card_0">대표이사 주요 이력</div>
                <div className="card card_1">
                  <ul>
                    <li>M, Arch. /공학박사수료 / 경영학 석 박사</li>
                    <li>LEED AP (미국 친환경건축 전문가)</li>
                    <li>BREEAM ASSESSOR (영국 친환경건축 평가사)</li>
                    <li>GSAS (중동 친환경건축 전문가)</li>
                  </ul>
                </div>
                <div className="card card_2">
                  <ul>
                    <li>ESG 그린리모델링 자문위원</li>
                    <li>(국토부 국토안전관리원 건설산업연구원 환경공단)</li>
                    <li>매경이코노미 ESG 경영 칼럼리스트</li>
                    <li>중앙대 ESG 최고위과정 대표 강사</li>
                    <li>서울시 기후변화기금운용 심의위원</li>
                    <li>강동구, 부천시, 광명시 경관심의위원</li>
                    <li>서울시 건축사회 자문위원(LCA)</li>
                    <li>(전)연세대, 중앙대, 인하대 외래교수 역임</li>
                  </ul>
                </div>
                <div className="card card_5">
                  <ul>
                    <li>연세대학교 공학사, 건축공학과 전공</li>
                    <li>Cornell University(미) 도시·건축대학원 석사</li>
                    <li>연세대학교 대학원 공학박사수료, 건축공학과(에너지) 전공</li>
                    <li>울대학교 환경대학원 도시환경 최고위과정24기 수료</li>
                    <li>고려대학교-KEDGE(프) 경영대학원 석사, 매니지먼트 전공</li>
                    <li>고려대학교-KEDGE(프) 경영대학원 박사, 마케팅 전공 </li>
                    <li>연세대학교 언론홍보대학원 언론홍보 최고위과정 46기 수료</li>
                  </ul>
                </div>
                <div className="card card_4">
                  <ul>
                    <li>
                      <span className="fc_g">연세 미래 여성 100인 선정</span>
                    </li>
                    <li>한국그린빌딩협의회, 한국녹색건축기술협회 위원장</li>
                    <li>한국생태환경건축학회, 한국실내환경학회 이사</li>
                    <li>서울시청, 제주도청, 기타도시 녹색건축물/환경 자문위원</li>
                    <li>2023 혁신한국인 & POWER KOREA 대상 (ESG/기술혁신부분)</li>
                    <li>2023 혁신한국인 & POWER KOREA 대상 (여성 친화 기업 부문)</li>
                    <li>2023 대한민국 혁신인물 / 기업 / 브랜드 대상 (탄소중립 건축문화)</li>
                    <li>
                      <span className="fc_g">2023 친환경 기술진흥 및 소비촉진 유공자 ‘산업포장’ 수상</span>
                    </li>
                  </ul>
                </div>
                <div className="card card_6">
                  <div className="text">
                    글로벌 친환경 건축 분야의 리더십 <br />
                    친환경 분야
                    <span className="fc_y">
                      국내외 다수의 프로젝트들을 직접 총괄 수행
                      <br /> 카타르 지사장
                    </span>
                    재임 시 에너지관리공단을 통한 중동지역에너지효율등급 수출 등<br />
                    아부다비 하타치변전소 특수 설계 등 글로벌 프로젝트 수행 중<br />
                    행복청 추천으로 자카르타 도시이전사업 스마트시티자문 현대건설, 토펙과 사우디 네옴시티 0공구, PDI 하와이 리조트 환경분야PM 제안 중 <br />
                    한국 영국 ‘넷제로 최적화 플랫폼 구축 국제 연구과제’ 선정, 싱가폴 국제 정부과제 제안 중 <br />
                    다수의 정부 기관 및 학술 단체, 협회에서 ESG 자문위원 및 이사로 활동
                  </div>
                </div>
                <div className="card card_3">
                  <ul>
                    <li>특허 등록 : ESG 기반 스마트 그린 건축 탄소중립 플랫폼</li>
                    <li>출원번호 : 10-2022-0144877</li>
                    <li>특허등록 : 교대 운전이 가능한 지중 열교환기</li>
                    <li>출원번호 : 10-2014-0138542</li>
                    <li className="blank"></li>
                    <li>특허출원 : 웰인증 기반의 AI 통합관리시스템</li>
                    <li>출원번호 : 10-2023-0049285 / 공동발명자</li>
                    <li>특허출원 : 통신 기반의 공기질 통합제어 시스템</li>
                    <li>출원번호 : 10-2023-0049286 /공동발명자</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CeoContent;
