import React from "react";
import "./styles/DesignCityContent.scss";
import BannerType1 from "../../../components/BannerType1";
const DesignCityContent = () => {
  const contents = [
    {
      category: "경관 디자인",
      list: [{ name: "심미성, 조화성, 지역성 등을 담은 경관 계획을 3차원 기반의 공간개념으로 제공", textList: ["3D 모델링과 항공촬영을 통해 구축된 조망점, 스카이라인 분석, 통경축 계획 및 주변 현황 분석"] }],
    },
    {
      category: "통합 기술 환경 디자인",
      list: [{ name: "친환경 기술력을 접목한 건축물 디자인을 통해 지속 가능한 환경을 조성하고 사회적 가치를 향상", textList: ["BIPV 등 에너지 생산 및 절감을 위한 외부 환경 디자인 제안"] }],
    },
    {
      category: "야간 경관 계획",
      list: [
        {
          name: "사람, 자연, 도시의 조화로운 야간경관계획을 통해 생태계 보호 및 에너지 절약",
          textList: ["‘서울시 조명관리 정책 (스카트 조명도시)’ 을 반영한 조명계획 제안", "미디어 아트를 접모한 야간경관계획", "야간경관계획 수립 (좋은빛 위원회 심의)"],
        },
      ],
    },
    {
      category: "공공디자인",
      list: [{ name: "서울의 공공시설물 수준 제고 및 도시경관 향상", textList: ["‘사용자 배려형 Interactive’ 시설물 디자인 제안"] }],
    },
    {
      category: "건축물 미술 장식품",
      list: [{ name: "순수문화예술의 진흥과 도시 환경에 예술적인 공간을 제공하여 시민의 삶의 질 향상", textList: ["Recycling art, 지속가능 미술 등 친환경 예술 제안"] }],
    },
    {
      category: "지오 갤러리",
      list: [{ name: "도심속 아트카페는 예술가들의 전시공간 확대와 지역 주민에게 문화 향유 기회를 제공", textList: [""] }],
    },
    {
      category: "빛환경 시뮬 레이션 (자연광 +인공광 특화 계획)",
      list: [
        { name: "일조일사 환경분석", textList: ["BIPV 등 에너지 생산 및 절감을 위한 외부 환경 디자인 제안"] },
        { name: "빛반사 (현휘) 영향 검토", textList: ["눈부심 발생원인 분석, 검증, 제안 등의 과정을 통해 전문적인 해결방안 도출"] },
        { name: "조도 / 휘도 시뮬레이션 (인공조명)", textList: ["빛방사 허용기준에 만족하는 설계를 위한 시뮬레이션"] },
      ],
    },
  ];
  return (
    <>
      <div className="design_city_content_wrap">
        <BannerType1 path="/imgs/banner_design_city.png" />
        <div className="title">스마트 경관 소개</div>
        <div className="content">
          <div className="des">다양한 프로젝트 경험, 풍부한 전문 인력, 신기술 네트워크 등을 기반으로 탈탄소 건축문화를 보급하는 도시 / 건축물경관 전반에 걸칠 Smart Green 인프라를 구축해 나아갑니다.</div>
          <div className="card_wrap">
            {contents.map((content: { category: string; list: { name: string; textList: string[] }[] }, index: number) => {
              return (
                <div className="card" key={`${content.category}_${index}`}>
                  <div className="category">{content.category}</div>
                  <div className="info_wrap">
                    {content.list.map((sub: { name: string; textList: string[] }) => {
                      return (
                        <div className="info" key={`${sub.name}_${index}`}>
                          <div className="name">{sub.name}</div>
                          <ul>
                            {sub.textList.map((text: string, index: number) => {
                              return text !== "" ? <li key={`${text}_${index}`}>{text}</li> : null;
                            })}
                          </ul>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default DesignCityContent;
