import React, { useEffect, useRef, useState } from "react";
import TitleFormat from "../../../components/TitleFormat";
import NewsRoomContent from "./NewsRoomContent/NewsRoomContent";
import NewsArticle from "./NewsArticle/NewsArticle";
import { MoveToTop } from "../../../hooks/MoveToTop";
import { useLocation } from "react-router-dom";

const NewsRoom = () => {
  const { state } = useLocation();
  const title = "다양한 분야에서 활동하고 있는\n 저희들의 소식을 알려드립니다";
  const [newsIndex, setNewsIndex] = useState(-1);
  useEffect(() => {
    MoveToTop();
  }, [newsIndex]);
  useEffect(() => {
    if (state.page !== undefined) setNewsIndex(Number(state.page));
  }, [state.page]);
  return (
    <>
      {newsIndex === -1 ? (
        <>
          <TitleFormat text={title} />
          <NewsRoomContent setNewsIndex={setNewsIndex} />
        </>
      ) : (
        <NewsArticle newsIndex={newsIndex} setNewsIndex={setNewsIndex} />
      )}
    </>
  );
};

export default NewsRoom;
