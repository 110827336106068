import React from "react";
import "./styles/CertificationCard.scss";
type Props = {
  path: string[];
};
const CertificationCard = ({ path }: Props) => {
  return (
    <div className="certification_card_wrap">
      {path.map((path, index) => {
        return <img src={path} key={`${index}`}></img>;
      })}
    </div>
  );
};

export default CertificationCard;
