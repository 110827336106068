import React from "react";
import TitleFormat from "../../../components/TitleFormat";
import EnergyStep from "../../../components/EnergyStep";
import NetZeroContent from "../../../components/NetZeroContent";
const title = { text: `탄소중립·에너지·시스템 컨설팅\n Net -Zero, Energy & Smart Green System` };
const content_01 = {
  title: "지속 가능한 건축물 구현을 위한\n탄소중립 특화 컨설팅",
  name_color: "#43583c",
  list: [
    {
      name: "탄소중립 / ZEB Total Solution / LCA",
      text: "사업 구상 단계에서 부터 에너지 사용량을 최소화하고 신재생에너지를 통한 에너지자립형 건축물을 계획하여 추가 용적률 확보 / 정부보조금 및 취득세 감면 / 에너지 사용요금 절감 / 건물의 가치 향상을 실현하는 컨설팅",
    },
    { name: "RE100  Solution (Renewable Energy 100)", text: "기업이 필요로 하는 전력사용량의 100%를 신재생에너지를 활용해 생생된 전력으로 대체하는 에너지자립형 솔루션" },
    { name: "온실가스 배출권거래제", text: "온실가스 감축활동에 대하여 자유로운 거래를 활성화하여 기후변화에 대응" },
  ],
};
const content_02 = {
  title: "건축 환경 시뮬레이션, 커미셔닝\n& TAB & MRV",
  name_color: "#bcb0a2",
  list: [
    {
      name: "건물과 도시환경 성능분석 및 평가 기술",
      text: "내·외부 일조·조망·경관해석, 소음·진동평가, 실내음향, 결빙·직광·현휘, 화재피난 안개위험도 분석 등의 건축환경 전분야의 현장측정 및 시뮬레이션 분석을 통한 신뢰성 검증과 최적의 컨설팅",
    },
    {
      name: "국내외 커미셔닝 & MRV",
      text: "설비 시스템의 효율적 성능 확보를 위한 가장 중요한 요소로서 건물의 전 과정에 걸쳐 모든 시스템의 계획, 설계, 시공, 성능시험 등을 확인하여 건물주의 요구를 충족할 수 있도록 검증하고 문서화하기 위한 컨설팅 탈탄소화 진단, M&V, MRV 및 성과검증",
    },
    {
      name: `설비 시스템 최적화
      (TestingAdjusting and Balancing)`,
      text: "설비초기 투자비와 불필요한 열손실 방지 및 운전비용을 절감하고, 품질향상과 공조설비의 수명연장을 통해 효율적인 시설관리를 위한 컨설팅",
    },
  ],
};
const NetZeroConsulting = () => {
  return (
    <>
      <TitleFormat text={title.text} />
      <EnergyStep />
      <NetZeroContent title={content_01.title} name_color={content_01.name_color} list={content_01.list} />
      <NetZeroContent title={content_02.title} name_color={content_02.name_color} list={content_02.list} />
      <div className="blank" style={{ maxWidth: 1920, width: "100%", height: 160 }}></div>
    </>
  );
};

export default NetZeroConsulting;
