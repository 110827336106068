import React, { useState } from "react";
import PageTemplate from "../../components/PageTemplate";
import RepresentativePerformance from "./RepresentativePerformance/RepresentativePerformance ";
import ProjectPerformance from "./ProjectPerformance/ProjectPerformance";
import TrackRecordClient from "./TrackRecordClient/TrackRecordClient";
import MeasuringInstrument from "./MeasuringInstrument/MeasuringInstrument";

const TrackRecord = () => {
  const [tap, setTap] = useState(0);
  return (
    <>
      <div className="track_record_wrap" style={{ maxWidth: "1920px", width: "100%" }}>
        <PageTemplate title="대표실적" menuList={["주거/비주거부문", "프로젝트 실적", "클라이언트", "검측장비"]} tap={tap} setTap={setTap} backgroundImage="/imgs/bg_trackrecord" />
        {(() => {
          switch (tap) {
            case 0:
              return <RepresentativePerformance />;
            case 1:
              return <ProjectPerformance />;
            case 2:
              return <TrackRecordClient />;
            case 3:
              return <MeasuringInstrument />;
            default:
              return <RepresentativePerformance />;
          }
        })()}
      </div>
    </>
  );
};

export default TrackRecord;
