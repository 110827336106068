import React from "react";
import "./styles/SaaSDtOverView.scss";

const SaaSDtOverView = () => {
  const contents = [
    {
      name: "ESG 플랫폼 구축",
      list: ["ESG를 도입하고자 하는 고객 대상 통합 컨설팅 & 운영 플랫폼 제시", "법적요구조건 검토 지원", "시뮬레이션/컨설팅/시공/운영지원", "스마트 홀 구축/탄소중립을 위한 교육 및 커뮤니티 공간 제공", "EGS보고서 제공"],
    },
    { name: "효율적 EMS 시스템 구축", list: ["용도별 에너지정보 수집", "실시간 모니터링 및 데이터 분석", "장비 상태이상유무 감시", "건물 최적화 에너지 제어"] },
    { name: "Net Zero 솔루션 플랫폼 구축", list: ["신재생 에너지 솔루션 프로그램 개발", "1차 에너지 절감을 위한 솔루션 공급", "건축물 LCA 분석 시스템 공급", "ESG 평가 보고 시스템 공급"] },
    { name: "실내공기질 모니터링 시스템", list: ["Well 인증 기반 실내 공기질 측정", "모니터링 시스템 구축", "AI를 이용한 재실자 파악", "시설 유형별 공기질 분석 방안 연구"] },
    { name: "탄소중립 지원 환경 제어", list: ["탄소 중립 지원을 위한 각종 환경 시스템을 통합적으로 제어하는 시스템 제공", "Active System 최적화로 에너지 소요량 절감 및 탈탄소화", "Renewable 에너지 적용으로 에너지 자립률 확보", "LCA 분석"] },
    { name: "GeO DT", list: ["IoT, 클라우드 컴퓨팅, 빅데이터를 기반으로 ESG 플랫폼을 구축하여 탄소중립 솔루션 제공"] },
  ];
  return (
    <>
      <div className="saas_dt_overview_wrap">
        <div className="title">
          지속가능한 탄소중립을 지향하는 <br /> 스마트 그린 ESG 플랫폼 구축
        </div>
        <div className="overview">
          <div className="sub_title">최첨단 AIoT IT 솔루션으로 ESG 플랫폼을 구축하는 SIT연구소는 기업을 위한 단계별 탄소감축 플랫폼 및 신뢰성 있는 데이터로 효율적인 전과정 탄소중립 ESG를 지속구현하도록 합니다</div>
          <div className="card_wrap">
            {contents.map((content: { name: string; list: string[] }, index: number) => {
              return (
                <div className="card" key={`${content.name}_${index}`}>
                  <div className="name">{content.name}</div>
                  <ul>
                    {content.list.map((text: string, index: number) => {
                      return <li key={`${text}_${index}`}>{text}</li>;
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default SaaSDtOverView;
