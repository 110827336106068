import React from "react";
import TotalEcoContent from "../components/TotalEcoContent";
import TitleFormat from "../components/TitleFormat";

const TotalEco = () => {
  const title = { text: "토탈 친환경 컨설팅\nBuilding Certification &\nConsulting" };

  return (
    <>
      <TitleFormat text={title.text} />
      <TotalEcoContent />
    </>
  );
};

export default TotalEco;
