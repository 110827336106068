import React, { useEffect, useState } from "react";
import "./styles/PageTemplate.scss";
import { useLocation } from "react-router-dom";
import useWidthSize from "../hooks/useWidthSize";
type Props = {
  title: string;
  titleColor?: string;
  menuList: string[];
  tap: number;
  setTap: React.Dispatch<React.SetStateAction<number>>;
  backgroundImage?: string | string[];
};
const PageTemplate = ({ title, titleColor, menuList, tap, setTap, backgroundImage }: Props) => {
  const { state } = useLocation();
  const handleTap = (e: any) => {
    setTap(Number(e.target.id));
  };
  useEffect(() => {
    if (state !== null) setTap(state.tap);
  }, [state]);
  const [size, setSize] = useState(250);
  useEffect(() => {
    const handleResize = () => {
      setSize(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const imageSize = () => {
    return size > 1024 ? "01" : size > 768 ? "02" : "03";
  };
  return (
    <>
      <div className="page_template_wrap">
        <div className="banner" style={{ backgroundImage: `url(${backgroundImage}_${imageSize()}.png)`, color: `${titleColor}` }}>
          {title}
        </div>
        <div className="tap_wrap">
          <ul>
            {menuList.map((menu: string, index: number) => {
              return (
                <li key={`${menu}_${index}`} id={String(index)} className={`${tap === index ? "active" : ""}`} onClick={handleTap}>
                  {menu}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default PageTemplate;
