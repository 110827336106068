import React from "react";
import TitleFormat from "../../../components/TitleFormat";
import BannerType1 from "../../../components/BannerType1";
import DesignGalleryContent from "./DesignGalleryContent";

const DesignGallery = () => {
  const title = { text: "아키테코그룹\n갤러리" };

  return (
    <>
      <TitleFormat text={title.text} />
      <BannerType1 path="/imgs/banner_design_gallery.png" />
      <DesignGalleryContent />
    </>
  );
};

export default DesignGallery;
