import React from "react";
import "./styles/NetZero.scss";
import { useNavigate } from "react-router-dom";
import BtnViewMore from "../../components/BtnViewMore";
const NetZero = () => {
  const navigate = useNavigate();
  const handleBtn = () => {
    navigate("/about_us", { state: { tap: 0 } });
  };
  return (
    <>
      <div id="netzero_wrap">
        <div className="inner">
          <div className="text_wrap">
            <h2 className="main_title_font">2050 탄소중립</h2>
            <BtnViewMore id="" onClick={handleBtn} />
          </div>
          <div className="img_box">
            <img src="/imgs/bg_home_netzero_goal.png" alt="netzero_goal" />
          </div>
        </div>
      </div>
    </>
  );
};

export default NetZero;
