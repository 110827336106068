import React, { useEffect, useRef, useState } from "react";
import TitleFormat from "../../../components/TitleFormat";
import "./styles/Business.scss";
import MapNaver from "../../../components/MapNaver";
import MapNaverJeju from "../../../components/MapNaverJeju";
//https://elvanov.com/2195
const Business = () => {
  const title = `ARCHITECO GROUP`;
  const titleWidth = 700;
  const [mapIndex, setMapIndex] = useState(0);
  const [offset1, setOffset1] = useState(0);
  const handleMap = (e: any) => {
    setMapIndex(Number(e.currentTarget.id));
  };

  const business_dt = [
    {
      path: "/imgs/business_area_01.png",
      name: "GeO Digital Twin, The Smart Green, ESG 기업형 Platform 및 시스템 개발",
      list: [
        "탄소/온실가스/에너지 관리",
        "탈탄소 성과모니터링",
        "BEMS",
        "AIoT 스마트 실내공기질 모니터링 시스템(지오 Air)",
        "물관리 시스템(지오 Water)",
        "설계 및 산출 프로그램, 유동 가시화 솔루션",
        "토탈 SIT(그린스마트 빌딩/ESG플랫폼/스마트홀)",
        "시스템 개발 및 사업화",
      ],
    },
    {
      path: "/imgs/business_area_02.png",
      name: "스마트 그린 설비 및 시스템 컨설팅",
      list: ["BEMS 구축", "그린데이타센터 커미셔닝 / 인증", "신재생에너지", "TAB / 커미셔닝", "온실가스 감축", "IBS 인증 및 시스템 구축", "성능 점검업(기계설비 , 소방,  전기안전)"],
    },
    {
      path: "/imgs/business_area_03.png",
      name: "스마트 / 그린 / 탈탄소 / 시스템 개발 및 사업화",
      list: ["탄소포집 (CCUS) 융합 설비 및 시스템 개발", "탈탄소 관련 설비", "건간 / 쾌적한 환경구현 설비", "키네틱 파사드 시스템"],
    },
  ];
  const business_rnd = [
    {
      path: "/imgs/business_area_04.png",
      name: "글로벌 스마트 그린·ESG 컨설팅 및 인증",
      list: [
        "(Net Zero) ESG / 기후변화보고서 (ISSB 기준)",
        "각종 스마트 그린 탄소인증 및 기준·규제 검토",
        "글로벌 탄소중립 컨설팅 및 ODA사업(ITMO)/SDM/RE100",
        "EU IFRS S1, S2, 택소노미/1호, 2호, 101호,TNFD반영 통합탄소회계",
        "부지환경검토, 환경DD",
        "LEED / BREEAM / GRESB / EDGE / WELL (HSR, Equity, etc) 등 각종 해외 인증",
        "에너지경영시스템 (ISO50001), 반부패경영시스템(ISO37001)",
        "CTCN, VCM",
      ],
    },
    {
      path: "/imgs/business_area_05.png",
      name: "탄소중립 및 탈탄소화 컨설팅R&D (VE/LCC, LCA)",
      list: [
        "기업/건축물 Net Zero ESG 보고서(ISSB/KSSB 기준)",
        "에너지/탈탄소화 진단/(AI기반)MRV/M&V(IPMVP)/성과정량화",
        "제로에너지빌딩 통합 솔루션",
        "LCA/EPD인증, VE / LCC",
        "정밀 에너지 시뮬레이션",
        "그린 리모델링",
        "온실가스 감축 및 탄소중립 제로에너지 특화",
        "탄소중립도시 / 넷제로 타운",
        "미래 / 그린스마트 시티",
        "신재생에너지 통합 컨설팅",
        "스마트그린 FM (유지관리)",
        "도시 물순환 / 물안심공간 / 그린, 블루, 인프라",
        "저영향개발",
        "교육 / 연구개발 / 출판",
      ],
    },
  ];
  const business_design = [
    {
      path: "/imgs/business_dt_design_01.png",
      name: "도시, 토목, 건축물 환경 디자인",
      list: [
        "경관 (스마트그린 / 도시 / 토목 / 건축물)",
        "조망 / 프라이버시 분석",
        "야간 경관 및 실시 조명설계 / 조도, 현위 분석",
        "좋은빛 위원회 심의",
        "공공디자인 및 시설물디자인",
        "건축물 미술장식품",
        "입면 특화 / 파사드 엔지니어링",
        "토탈디자인 및 색채디자인",
        "미디어 파사드",
        "건축물 미술장식품",
      ],
    },
    {
      path: "/imgs/business_dt_design_02.png",
      name: "특화 디자인 및 솔루션",
      list: ["특수 설계 시공", "모듈러", "키네틱 파사드", "그린리모델링", "기밀설계", "디지털 스마트 공간", "패시브 하우스"],
    },
  ];
  const business_total = [
    {
      path: "/imgs/business_dt_eco_01.png",
      name: "녹색 건축 인증 및 친환경 계획 컨설팅",
      list: [
        "녹색건축물인증(G-SEED)",
        "제로에너지건축물인증(ZEB)",
        "에너지사용계획서",
        "건축물에너지효율등급인증",
        "장애물없는생활환경인증(BF)",
        "장수명주택인증",
        "범죄예방환경설계인증",
        "에너지절약계획서",
        "에너지절약형친환경주택",
        "공동주택결로방지설계",
        "건강친화형주택",
        "수질오염총량제",
        "교육환경영향및 교육시설안전성 평가",
      ],
    },
    {
      path: "/imgs/business_dt_eco_02.png",
      name: "환경성능 시뮬레이션 및 측정",
      list: [
        `기류환경\n실내 공기질 및 단지내 바람길/연돌 영향분석/실내 공기질/초고층풍압, 빌딩풍, 기계실 실내 공조 환경, 바닥급기 분석/로비, 아트리움, 대공간 공조성능/취기 확산`,
        `열환경\n열, 결로 분석/에너지 시뮬레이션/재생에너지 시뮬`,
        `빛환경\n일조/일사/빛 반사 영향 검토/선큰 및 업무공간 자연채광`,
        `소음환경\n도로교통소음/층간, 세대간 소음`,
      ],
    },
  ];
  const content = (
    content: {
      path: string;
      name: string;
      list: string[];
    }[]
  ) => {
    return content.map((content: { path: string; name: string; list: string[] }, index: number) => {
      return (
        <div className="card" key={`${content.name}_${index}`}>
          <div className="img_box">
            <img src={`${content.path}`} alt="" />
          </div>
          <div className="name">{content.name}</div>
          <ul>
            {content.list.map((text: string, index: number) => {
              return <li key={`${text}_${index}`}>{text}</li>;
            })}
          </ul>
        </div>
      );
    });
  };
  return (
    <>
      <div id="business_wrap">
        <TitleFormat text={title} />
        <div className="s_wrap">
          <div className="i_wrap">
            <img src="/imgs/aboutus_business_sample_01.png" alt="" />
          </div>
          <div className="i_wrap">
            <img src="/imgs/aboutus_business_sample_02.png" alt="" />
          </div>
        </div>
        <div className="business_list_wrap">
          <div className="title">Business Area</div>
          <div className="content_wrap">
            <div className="left">
              <span>DT</span>
            </div>
            <div className="right">{content(business_dt)}</div>
          </div>
          <div className="content_wrap">
            <div className="left">
              <span>R&D</span>
            </div>
            <div className="right">{content(business_rnd)}</div>
          </div>
          <div className="content_wrap">
            <div className="left">
              <span>디자인</span>
            </div>
            <div className="right">{content(business_design)}</div>
          </div>
          <div className="content_wrap">
            <div className="left">
              <span>토탈 친환경 계획</span>
            </div>
            <div className="right">{content(business_total)}</div>
          </div>
        </div>
        <div className="m_wrap">
          <div className="sub_title">찾아오시는길</div>
          <div className="des">
            서울특별시 강남구 삼성로 575 / 지번 : 서울특별시 강남구 <br /> 삼성동 119 - 2 (아키테코그룹 사옥)
          </div>
          <div className="tap_wrap">
            <ul>
              <li id="0" className={`${0 === mapIndex ? "active" : ""}`} onClick={handleMap}>
                서울 지사
              </li>
              <li id="1" className={`${1 === mapIndex ? "active" : ""}`} onClick={handleMap}>
                제주도 지사
              </li>
            </ul>
          </div>
          {mapIndex === 0 ? (
            <>
              <div className="map_wrap">
                <MapNaver />
              </div>
              <div className="info_wrap">
                <div className="info parking_wrap">
                  <div className="left">
                    <div className="icon_text">P</div>
                    <div className="text">주차장 이용안내</div>
                  </div>
                  <div className="right">
                    <div className="parking_point_1">
                      <div className="fw_b">대한 해운 주차장</div>
                      <div>주소 : 서울특별시 강남구 삼성로 570</div>
                    </div>
                    <div className="parking_point_2">
                      <div className="fw_b">삼성 민영 주차장</div>
                      <div>주소 : 서울 강남구 봉은사로 478 창우빌딩</div>
                    </div>
                  </div>
                </div>
                <div className="info subway_wrap">
                  <div className="left">
                    <div className="icon_box">
                      <img src="/imgs/icon_subway.png" alt="subway_icon" />
                    </div>
                    <div className="text">지하철 이용안내</div>
                  </div>
                  <div className="right">
                    <div className="point_01">
                      <div>
                        <span className="ball">9</span>
                        <span className="fw_b fc_y">호선 삼성중앙역</span>
                      </div>
                      <div>
                        <span className="fw_b">6번</span> 출구로 나오면 바로 찾아보실수 있습니다.
                      </div>
                    </div>
                    <div className="point_02">
                      <span className="fw_b">7번</span> 출구로 나와서 뒤로돌아 45m 정도 걸어오시면 바로 찾아보실수 있습니다.
                    </div>
                  </div>
                </div>
                <div className="info bus_wrap">
                  <div className="left">
                    <div className="icon_text">B</div>
                    <div className="text">정류장 이용안내</div>
                  </div>
                  <div className="right">
                    <div className="point">
                      <div className="station">
                        <div className="fw_b">삼성중앙역.플래티넘아파트</div>
                        <div>(봉은사.코엑스북문방면)</div>
                      </div>
                      <div className="bus_route">
                        <div>
                          <span className="fw_b fc_b">간선</span> 342번
                        </div>
                        <div>
                          <span className="fw_b fc_g">지선</span> 3412번
                        </div>
                        <div>
                          <span className="fw_b fc_lg">마을</span> 강남07번
                        </div>
                      </div>
                    </div>
                    <div className="point">
                      <div className="station">
                        <div className="fw_b">삼성중앙역.플래티넘아파트</div>
                        <div>(삼성2문화센터 방면)</div>
                      </div>
                      <div className="bus_route">
                        <div>
                          <span className="fw_b fc_b">간선</span> 342번
                        </div>
                        <div>
                          <span className="fw_b fc_g">지선</span> 3412번
                        </div>
                        <div>
                          <span className="fw_b fc_lg">마을</span> 강남07번
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="map_wrap">
                <MapNaverJeju />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Business;
