import React from "react";
import "./styles/CiSignature.scss";
type Props = {
  title: string;
  ment: any;
  img_path_01: string;
  img_path_02: string;
  img_path_03: string;
  img_path_04: string;
  img_path_05: string;
  img_path_06: string;
};
const CiSignature = ({ title, ment, img_path_01, img_path_02, img_path_03, img_path_04, img_path_05, img_path_06 }: Props) => {
  return (
    <>
      <div className="ci_signature_wrap">
        <h2>{title}</h2>
        <div className="ci_main_wrap">
          <div className="img_box">
            <img src={img_path_01} alt="architeco_group_ci" />
          </div>
          <div className="ment">{ment}</div>
        </div>
        <div className="ci_color_wrap">
          <div className="ci_color_box">
            <div className="img_box">
              <img src={img_path_02} alt="architeco_group_ci_black" />
            </div>
            <div className="text_wrap">
              <span>블랙</span>
              <span>#111111</span>
            </div>
          </div>
          <div className="ci_color_box">
            <div className="img_box">
              <img src={img_path_03} alt="architeco_group_ci_white" />
            </div>
            <div className="text_wrap">
              <span>화이트</span>
              <span>#FFFFFF</span>
            </div>
          </div>
          <div className="ci_color_box">
            <div className="img_box">
              <img src={img_path_04} alt="architeco_group_ci" />
            </div>
            <div className="text_wrap">
              <span>원본</span>
            </div>
          </div>
        </div>
        <div className="ci_intverval_wrap">
          <div className="interval_wrap">
            <div className="img_box">
              <img src={img_path_05} alt="ci_interval" />
            </div>
            <span>간격</span>
          </div>
          <div className="interval_wrap">
            <div className="img_box">
              <img src={img_path_06} alt="ci_interval" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CiSignature;
