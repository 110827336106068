import React from "react";
import ProjectPerformanceContent from "./ProjectPerformanceContent";

const ProjectPerformance = () => {
  const contents_01 = [
    {
      name: "1. 글로벌 프로젝트",
      list: [
        "한-영 양자협력형 국토교통 국제공동연구 정부과제(고려대, 중앙대)",
        `한-싱가포르 디지털혁신기술 국제공동 연구사업(서울대, 고려대)\n : 인공지능 기반 도시 냉각 기술개발`,
        "UAE 히타치 변전소 (HVDC) 기밀성능 개선 용역",
        "사우디 네옴시티 (현대 컨소 제안 중)",
      ],
      option: ["fc_y", "", "fc_y", ""],
    },
    {
      name: "2. 스마트 그린 플랫폼 / 디지털트윈",
      list: [
        "삼성 웰인증 기반 IoT 실내공기질 시스템 개발",
        "실내공기질 통합관리시스템 프로그램 개발 용역 다수",
        "지열시스템 초기 검토 프로그램 개발",
        "롯데 탄소중립 1단계 친환경 가이드 및 프로그램 개발",
        "언더그라운드시티 기술개발 기획 \n : AI를 활용한 지하공간 프로그래밍 시뮬레이션",
        "안전협회 중대재해처벌법대비 안전관리 앱 개발",
      ],
      option: ["fc_y", "", "", "fc_y", "", "", ""],
    },
    {
      name: "3. 주요 탄소중립 정부과제",
      list: [
        "넷제로 에너지 달성을 위한 건물 커뮤니티 데이터 기반 \n : 신재생에너지 설계 및 운영 최적화 응용기술 개발",
        "인공지능 기반 도시 냉각 기술개발",
        "웰빙 주거환경 조성을 위한 스마트하우징 기술개발 기획",
        "목조건축의 전과정 평가(LCA)를 통한 건축물의 탄소중립 실현방안 연구",
        "다소비 FEMS 실증 R&D과제의 M&V계획 검증",
        "K-water 물안심공간 조성사업 프로젝트  기획과제",
        "국토교통 DNA플러스 융합 연구과제",
      ],
      option: ["fc_y", "fc_y", "fc_y", "fc_y", "", "fc_y", ""],
    },
    {
      name: "4. 전과정 탄소배출량 산출 용역(LCA)",
      list: [
        "에코앤로지스 부산 친환경 국제물류센터 LCA 인증",
        "아산스마트밸리 C2BL 공동주택 LCA 인증",
        "한국경제신문 부평 윤전공장 신축공사 LCA 인증",
        "TOK 첨단재료㈜ 신검사동 건설공사 LCA 인증",
        "울산다운 2지구 B-4BL 공동주택 신축공사 LCA 인증",
        "서울시 건축사협회 산업연구원 목조건물 LCA를 통한 탄소중립 실현방안 연구",
      ],
      option: ["", "", "", "", ""],
    },
    {
      name: "5. 국내외 탄소중립도시 기획 및 제안",
      list: [
        "포항시 물안심도시 : K Water",
        "인도네시아 신수도 누산타라 이전:행복청, 현지 신수도이전 사업단",
        "안산 탄소중립도시",
        "제천시 스마트산단 : 제천시청",
        "강진구 소멸대응 기금",
        "건기원 언더그라운드 시티개발(공간 DT)",
        "롯데건설 탄소중립1단계 친환경가이드라인",
        "여주 넷제로 타운하우스",
        "하와이 리조트 : PDI",
      ],
      option: ["", "", "", "", "", "", "", "", ""],
    },
  ];

  const contents_02 = [
    {
      name: "1. 공동주택",
      list: ["힐스테이트 검단 웰카운티(LH 최초 제로에너지 공동주택)", "강릉 경포지구 개발사업(공동주택)", "화성 동탄 SK 레이크원", "동교동 157-1 역세권활성화사업", "평택 안중 현화지구 36BL · 포항 구룡포 마린시티 등"],
      option: ["fc_y", "", "", "", ""],
    },
    {
      name: "2. 재건축 / 재개발 정비사업",
      list: ["서초 잠원 동아 공동주택 리모델링", "동작 노량진 4차 주택재개발 · 성내 삼성아파트 리모델링,", "대구 메트로팔레스 1,2,3,5단지 등 리모델링"],
      option: ["fc_y", "", ""],
    },
    {
      name: "3. 업무, 판매 호텔 外 / IBS 컨설팅",
      list: ["사학연금 서울회관", "상암 디지털미디어시티 복합쇼핑몰", "부산 오시리아 호텔 & 리조트(신라) / 경포대 리조트", "국립진주박물관 / 충남미술관(기술제안) / 충남컨벤션(기술제안)"],
      option: ["", "", "", ""],
    },
    {
      name: "4. 오피스텔, 오피스",
      list: ["청담동 98-6 오피스텔", "평택 안중현화지구 상업용지"],
      option: ["", ""],
    },
    {
      name: "5. 병원, 연구소, 청사 / 학교시설",
      list: [
        "경포대 초등학교 이전 신축설계",
        "서울 양병원 본관 및 신관",
        "군산전북대병원(기술제안)",
        "충남대 외 2개교 임대형 민자사업/육군장성, 철원간부숙소(BTL)",
        "부천대학교 소사캠퍼스 2단계",
        "이화여자대학교 / 고려대학교 / 숭실대학교 / 기상청 기후센터　　　　　　　　(국제협상 설계 : 넷제로)",
        "양천우체국",
      ],
      option: ["", "", "", "", "", "", ""],
    },
    {
      name: "6. FED / LEED 프로젝트",
      list: ["평택 Humphreys East 초등학교 LEED", "김해 대동 물류센터 LEED", "잠실스포츠 MICE 복합공간 조성 LEED"],

      option: ["", "", ""],
    },
    {
      name: "7. 데이터센터 / 지식센터 / 물류센터",
      list: ["부천 내동, 안양박달, A사 데이터센터 등", "용인 기흥 ICT밸리 등 지식센터", "김해 대동 물류센터, 부산 에코 앤 로지스  물류센터", "마곡 R&D센터, 마곡 KW 지식산업센터", "과천 지식정보타운 상업 2BL, 4BL"],
      option: ["", "", "", "", ""],
    },
    {
      name: "8. 국내외 친환경 건축연구용역 / 설계시공지침 개발 (대표개인 실적)",
      list: [
        "카타르 에너지효율등급 수출 (에너지관리공단) / 기타 글로벌 프로젝트 다수",
        "저에너지 친환경 주거단지 디자인 매뉴얼 개발",
        "저탄소 친환경 건축개발, 에너지 기준 수립 연구",
        "LEED 매뉴얼 / LEED 시공계획서 및 프로그램 개발",
        "에너지,친환경, 주거성능 가이드라인 수립 연구",
        "친환경인증 / LEED 의사결정 프로그램 개발",
        "BIM 기반 시공사 환경성능 툴 개발",
        "BEMS 개발 / 실내공기질 모니터링 시스템 개발",
      ],
      option: ["", "", "", "", "", "", "", ""],
    },
  ];
  return (
    <>
      <div className="project_performance_wrap" style={{ paddingBottom: "15%" }}>
        <ProjectPerformanceContent title="국내외 대표 프로젝트 실적" contents={contents_01} height="820px" />
        <ProjectPerformanceContent title="친환경 건축물 및 건물 에너지 관련" contents={contents_02} height="500px" />
      </div>
    </>
  );
};

export default ProjectPerformance;
