import React from "react";
import TitleFormat from "../../../components/TitleFormat";
import DesignSolutionContent from "./DesignSolutionContent";

const DesignSolution = () => {
  const title = { text: "ESG 디자인 솔루션\nGreen Design Tech" };
  return (
    <>
      <TitleFormat text={title.text} />
      <DesignSolutionContent />
    </>
  );
};

export default DesignSolution;
