import React from "react";
import "./NewsArticle.scss";
import { news } from "../../../../utils/news";
type Props = {
  newsIndex: number;
  setNewsIndex: React.Dispatch<React.SetStateAction<number>>;
};

const NewsArticle = ({ newsIndex, setNewsIndex }: Props) => {
  const handleBack = () => {
    setNewsIndex(-1);
  };
  const handleMove = (page: number) => {
    setNewsIndex(newsIndex + page);
  };
  return (
    <>
      <div id="news_article_wrap">
        <div className="news_header">
          <button onClick={handleBack}>
            <img src="/imgs/btn_back_arrow.png" alt="back button" />
          </button>
          <div className="title">{news[newsIndex].title}</div>
          <div className="date">{news[newsIndex].date}</div>
        </div>
        <div className="article_wrap">{news[newsIndex].des}</div>
        <div className="n_wrap">
          {newsIndex === 0 ? null : (
            <div
              className="m_wrap pre_wrap"
              onClick={() => {
                handleMove(-1);
              }}
            >
              <div className="s pre">이전글</div>
              <div className="t pre_title">
                <span>{news[newsIndex - 1].title}</span>
              </div>
            </div>
          )}
          {newsIndex === news.length - 1 ? null : (
            <div
              className="m_wrap next_wrap"
              onClick={() => {
                handleMove(1);
              }}
            >
              <div className="s next">다음글</div>
              <div className="t next_title">
                <span>{news[newsIndex + 1].title}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default NewsArticle;
