import React from "react";
import "./styles/Client.scss";

const Client = () => {
  const clientArray = [
    "/imgs/client_01.png",
    "/imgs/client_02.png",
    "/imgs/client_03.png",
    "/imgs/client_04.png",
    "/imgs/client_05.png",
    "/imgs/client_06.png",
    "/imgs/client_07.png",
    "/imgs/client_08.png",
    "/imgs/client_09.png",
    "/imgs/client_10.png",
    "/imgs/client_11.png",
    "/imgs/client_12.png",
    "/imgs/client_13.png",
    "/imgs/client_14.png",
    "/imgs/client_15.png",
    "/imgs/client_16.png",
    "/imgs/client_17.png",
    "/imgs/client_18.png",
    "/imgs/client_19.png",
    "/imgs/client_20.png",
    "/imgs/client_21.png",
    "/imgs/client_22.png",
    "/imgs/client_23.png",
    "/imgs/client_24.png",
    "/imgs/client_25.png",
    "/imgs/client_26.png",
    "/imgs/client_27.png",
    "/imgs/client_28.png",
    "/imgs/client_29.png",
    "/imgs/client_30.png",
    "/imgs/client_31.png",
    "/imgs/client_32.png",
    "/imgs/client_33.png",
    "/imgs/client_34.png",
    "/imgs/client_35.png",
  ];
  const client2Array = [
    ["/imgs/client_01.png", "/imgs/client_02.png", "/imgs/client_03.png", "/imgs/client_04.png", "/imgs/client_05.png", "/imgs/client_06.png", "/imgs/client_07.png", "/imgs/client_08.png", "/imgs/client_09.png"],
    ["/imgs/client_10.png", "/imgs/client_11.png", "/imgs/client_12.png", "/imgs/client_13.png", "/imgs/client_14.png", "/imgs/client_15.png", "/imgs/client_16.png", "/imgs/client_17.png", "/imgs/client_18.png"],
    ["/imgs/client_19.png", "/imgs/client_20.png", "/imgs/client_21.png", "/imgs/client_22.png", "/imgs/client_23.png", "/imgs/client_24.png", "/imgs/client_25.png", "/imgs/client_26.png", "/imgs/client_27.png"],
    ["/imgs/client_28.png", "/imgs/client_29.png", "/imgs/client_30.png", "/imgs/client_31.png", "/imgs/client_32.png", "/imgs/client_33.png", "/imgs/client_34.png", "/imgs/client_35.png"],
  ];
  return (
    <>
      <div className="client_wrap">
        <div className="title_wrap">
          <h2>클라이언트</h2>
          <div className="des">아키테코 그룹과 함께해주시는 기업입니다.</div>
        </div>
        <div className="content_wrap">
          {/* {clientArray.map((path: string, index) => {
            return (
              <div className="img_box" key={index}>
                <img src={path} alt="client" />
              </div>
            );
          })} */}
          {client2Array.map((array: string[], index: number) => {
            return (
              <div className="row" key={`${array[index]}_${index}`}>
                {array.map((path: string, index2: number) => {
                  return (
                    <div className="img_box" key={`${path}-${index2}`}>
                      <img src={path} alt="client" />
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Client;
