import React, { act, useEffect, useState } from "react";
import "./styles/TrackRecord.scss";
import BtnViewMore from "../../components/BtnViewMore";
import { useNavigate } from "react-router-dom";
import useWindowSizeCustom from "../../hooks/useWindowSizeCustom ";
const TrackRecord = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState(0);
  const contents = [
    { path: "/imgs/home_track_record_01.png", name: "이화여자대학교 생활환경관" },
    { path: "/imgs/home_track_record_02.png", name: "힐스테이트 검단 웰카운티" },
    { path: "/imgs/home_track_record_03.png", name: "잠원 동아 주택재건축정비사업" },
    { path: "/imgs/home_track_record_04.png", name: "강릉시 저동 공동주택" },
    { path: "/imgs/home_track_record_05.png", name: "오시리아 호텔 & 리조트" },
    { path: "/imgs/home_track_record_06.png", name: "마곡 R&D센터" },
    { path: "/imgs/home_track_record_07.png", name: "서울 서초 잠원동아아파트 리모델링" },
    { path: "/imgs/home_track_record_08.png", name: "강릉시 경포지구 근린생활시설" },
    { path: "/imgs/home_track_record_09.png", name: "상암 디지털 미디어 시티 복합쇼핑몰" },
    { path: "/imgs/home_track_record_10.png", name: "잠실 스포츠 MICE 복합공간" },
    { path: "/imgs/home_track_record_11.png", name: "평택 안중 현화지구 36BL" },
    { path: "/imgs/home_track_record_12.png", name: "부천 내동 데이터 센터" },
  ];
  const handleBtnPre = () => {
    if (active !== 0) setActive(active - 1);
  };
  const handleBtnNext = () => {
    if (active < contents.length - 1) setActive(active + 1);
  };
  const handleViewMore = () => {
    navigate("/trackrecord", { state: { tap: 0 } });
  };

  return (
    <>
      <div id="home_track_record_wrap">
        <div className="title_wrap">
          <h2>대표 실적</h2>
          <span>아키테코 그룹의 대표 주거/비주거 실적 내용입니다.</span>
        </div>
        <div className="content">
          <div className="card_wrap" style={{ left: `-${active * (Number(useWindowSizeCustom().width) > 767 ? 666 : 229)}px` }}>
            {contents.map((content: { path: string; name: string }, index: number) => {
              return (
                <div id={`${index}`} className={`card ${index < active ? "pre" : index > active ? "next" : ""} ${index === active ? "active" : ""}`} key={`${content.name}_${index}`}>
                  <img src={content.path} alt={`${content.name}`} />
                </div>
              );
            })}
          </div>
          <div className="name">{contents[active].name}</div>
          <BtnViewMore onClick={handleViewMore} />
          <div className="paging">
            <button onClick={handleBtnPre} className={`${active === 0 ? "n" : "btn"}`}>
              <svg className="arrow pre" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="18" cy="18" r="18" fill="#F1F1F1" />
                <path d="M19 26L11 18L19 10" stroke="#111111" />
                <path d="M11 18H25.5" stroke="#111111" />
              </svg>
            </button>
            <button onClick={handleBtnNext} className={`${active === contents.length - 1 ? "n" : "btn"}`}>
              <svg className="arrow next" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="18" cy="18" r="18" transform="rotate(-180 18 18)" fill="#F1F1F1" />
                <path d="M17.5 10L25.5 18L17.5 26" stroke="#111111" />
                <path d="M25.5 18L11 18" stroke="#111111" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrackRecord;
