import React from "react";
import "./App.css";
import Header from "./components/Header";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import Footer from "./components/Footer";
import AboutUs from "./pages/AboutUs/AboutUs";
import Business from "./pages/AboutUs/Business/Business";
import DigitalTwin from "./pages/DigitalTwin/DigitalTwin";
import It from "./pages/It/It";
import Design from "./pages/Design/Design";
import Consulting from "./pages/Consulting";
import TrackRecord from "./pages/TrackRecord/TrackRecord";
import News from "./pages/News/News";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about_us" element={<AboutUs />} />
          <Route path="/digitaltwin" element={<DigitalTwin />} />
          <Route path="/globalit" element={<It />} />
          <Route path="/design" element={<Design />} />
          <Route path="/consulting" element={<Consulting />} />
          <Route path="/trackrecord" element={<TrackRecord />} />
          <Route path="/news" element={<News />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
