import React, { useState } from "react";
import PageTemplate from "../../components/PageTemplate";
import ItDecarbonization from "./ItDecarbonization/ItDecarbonization";
import ItCertification from "./ItCertification/ItCertification";
import ItEnvironment from "../ItEnvironment";
import NetZeroConsulting from "./NetZeroConsulting/NetZeroConsulting";

const It = () => {
  const [tap, setTap] = useState(0);

  return (
    <>
      <PageTemplate title="글로벌 ESG R&D" menuList={["탈탄소화 컨설팅", "글로벌 인증 및 기준", "탄소중립 에너지 컨설팅"]} tap={tap} setTap={setTap} backgroundImage="/imgs/bg_globalit" />
      <div className="it_wrap" style={{ maxWidth: "1920px", width: "100%" }}>
        {(() => {
          switch (tap) {
            case 0:
              return <ItDecarbonization />;
            case 1:
              return <ItCertification />;
            case 2:
              return <NetZeroConsulting />;
            default:
              return <ItDecarbonization />;
          }
        })()}
      </div>
    </>
  );
};

export default It;
