import React, { useState } from "react";
import PageTemplate from "../../components/PageTemplate";
import NewsRoom from "./NewsRoom/NewsRoom";
import Academy from "./Academy";

const News = () => {
  const [tap, setTap] = useState(0);
  return (
    <>
      <div className="new_wrap" style={{ maxWidth: "1920px", width: "100%" }}>
        <PageTemplate title="뉴스" titleColor="#111111" menuList={["뉴스룸", "아카데미"]} tap={tap} setTap={setTap} backgroundImage="/imgs/bg_news" />

        {(() => {
          switch (tap) {
            case 0:
              return <NewsRoom />;
            case 1:
              return <Academy />;
            default:
              return <NewsRoom />;
          }
        })()}
      </div>
    </>
  );
};

export default News;
