import React, { useEffect, useState } from "react";
import "./styles/ProjectPerformanceContent.scss";
type Props = {
  title: string;
  contents: { name: string; list: string[]; option: string[] }[];
  height: string;
};
const ProjectPerformanceContent = ({ title, contents, height }: Props) => {
  const [cardActive, setCardActive] = useState([false]);
  const handleCard = (e: any) => {
    const id = e.currentTarget.id;
    const newCard = [...cardActive];
    newCard[id] = !newCard[id];
    setCardActive(newCard);
  };
  useEffect(() => {
    setCardActive(Array.from({ length: contents.length }, () => false));
  }, [contents]);
  return (
    <>
      <div className="project_performance_content">
        <div className="title">{title}</div>
        <div className="content">
          {contents.map((content: { name: string; list: string[]; option: string[] }, index: number) => {
            return (
              <div className="card_wrap" key={`${content.name}_${index}`}>
                <div className="card" key={`${content.name}_${index}`}>
                  <div className="name" id={String(index)} onClick={handleCard}>
                    {content.name}
                    <button id={String(index)} className={`${cardActive[index] ? "btn_active" : ""}`}>
                      <img src="/imgs/arrow.png" alt="arrow" />
                    </button>
                  </div>
                  <ul className={`${cardActive[index] ? "accordion_active" : ""}`}>
                    {content.list.map((text: string, index: number) => {
                      return (
                        <li key={`${text}_${index}`} className={content.option[index]}>
                          {text}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ProjectPerformanceContent;
