import React from "react";
import "./styles/SaaSDtPlatform.scss";
const SaaSDtPlatform = () => {
  return (
    <>
      <div className="saas_dt_platform_wrap">
        <div className="contents">
          <div className="i_wrap">
            <img src="/imgs/dt_saasdt_sample.png" alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default SaaSDtPlatform;
