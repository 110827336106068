import React from "react";
import TitleFormat2 from "../../../components/TitleFormat2";

const MeasuringInstrument = () => {
  const title = { text: "검측장비" };
  return (
    <>
      <TitleFormat2 text={title.text} />
      <div className="content" style={{ padding: "5%", display: "flex", justifyContent: "center" }}>
        <img src="/imgs/measuring_instrument_table.png" alt="measuring_instrument_table" style={{ maxWidth: "1440px", width: "100%" }} />
      </div>
    </>
  );
};

export default MeasuringInstrument;
