import React, { useEffect, useRef, useState } from "react";
import "./styles/MainBanner.scss";

const MainBanner = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    videoRef.current?.play();
  }, []);
  return (
    <>
      <div className="main_banner_wrap">
        <div className="video_wrap">
          <video ref={videoRef} autoPlay muted loop>
            <source src="/video/main_vedio.mp4" type="video/mp4" />
          </video>
        </div>
        <div className="txt_wrap">
          <h2>NET - ZERO 기반 기후테크 전문 기업</h2>
          <h3>
            The Smart Green <span className="bar">|</span> ESG Platform Leader
          </h3>
          <p>
            ㈜ 아키테코 그룹은 <span>넷제로</span> 기반 스마트 그린 <span>ESG플랫폼</span> 전문 그룹으로 고도화된 기후테크 기술 컨설팅 및 솔루션을 제시하고 있습니다.
          </p>
          <ul>
            <li>플랫폼</li>
            <li>솔루션</li>
            <li>컨설팅( MRV, 인증, 시뮬레이션 )</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default MainBanner;
