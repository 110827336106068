import React, { useEffect, useRef, useState } from "react";
import "./NewsRoomContent.scss";
import { news } from "../../../../utils/news";
import BannerType1 from "../../../../components/BannerType1";
type Props = {
  setNewsIndex: React.Dispatch<React.SetStateAction<number>>;
};
const NewsRoomContent = ({ setNewsIndex }: Props) => {
  const handleNews = (e: any) => {
    setNewsIndex(Number(e.currentTarget.id));
  };

  return (
    <>
      <div id="news_room_content_wrap">
        <BannerType1 path="/imgs/banner_news_room.png" />
        <div className="title">보도 자료</div>
        <div className="contents">
          {news.map((content: { path: string; title: string; date: string; des: any }, index: number) => {
            return (
              <div id={`${index}`} className="card" key={`${content.title}_${index}`} onClick={handleNews}>
                <div className="img_box">
                  <img src={`${content.path}`} alt={`${content.title}`} />
                </div>
                <div className="name">{content.title}</div>
                <div className="date">{content.date}</div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default NewsRoomContent;
