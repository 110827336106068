import React from "react";
import MainBanner from "./MainBanner";
import NetZero from "./NetZero";
import BusinessInfo from "./BusinessInfo";
import IntroductionVideo from "./IntroductionVideo";
import TrackRecord from "./TrackRecord";
import Certification from "./Certification";
import Client from "./Client";
import BusinessInfoType02 from "./BusinessInfoType02";
import NewsPreview from "./NewsPreview";
const Home = () => {
  return (
    <>
      <div className="home_wrap" style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <MainBanner />
        <NetZero />
        <BusinessInfo />
        <BusinessInfoType02 />
        <TrackRecord />
        <Certification />
        <IntroductionVideo />
        {/* <NewsPreview /> */}
        <Client />
        <img src="/imgs/logos_certification.png" alt="certification-logs" style={{ width: "100%" }} />
      </div>
    </>
  );
};

export default Home;
