import { useEffect, useRef } from "react";

const MapNaver = () => {
  const mapElement = useRef(null);
  const { naver } = window;

  useEffect(() => {
    if (!mapElement.current || !naver) return;
    const parkingPoint = [
      { x: 37.51235, y: 127.054 },
      { x: 37.51245, y: 127.0528 },
    ];

    // 지도에 표시할 위치의 위도와 경도 좌표를 파라미터로 넣어줍니다.
    // architeco-geo -> 37.51273, 127.05315
    const location = new naver.maps.LatLng(37.51273, 127.05315);
    const mapOptions = {
      center: location,
      zoom: 19,
      // zoomControl: true,
    };

    const map = new naver.maps.Map(mapElement.current, mapOptions);
    new naver.maps.Marker({
      map: map,
      position: location,
      title: "회사",
      icon: {
        url: "/imgs/icon_office_point.png",
        size: new naver.maps.Size(180, 83),
        anchor: new naver.maps.Point(0, 83),
      },
    });

    const markers: any[] = [];
    for (var key in parkingPoint) {
      var marker = new naver.maps.Marker({
        map: map,
        position: new naver.maps.LatLng(parkingPoint[key].x, parkingPoint[key].y),
        title: "주차장",
        icon: {
          url: "/imgs/icon_parking_point.png",
          // size: new naver.maps.Size(24, 37),
          // anchor: new naver.maps.Point(12, 37),
          // origin: new naver.maps.Point(parkingPoint[key].x, parkingPoint[key].y),
        },
        zIndex: 100,
      });
      markers.push(marker);
    }

    naver.maps.Event.addListener(map, "idle", function () {
      updateMarkers(map, markers);
    });

    function updateMarkers(map: any, markers: any) {
      var mapBounds = map.getBounds();
      var marker, position;

      for (var i = 0; i < markers.length; i++) {
        marker = markers[i];
        position = marker.getPosition();

        if (mapBounds.hasLatLng(position)) {
          showMarker(map, marker);
        } else {
          hideMarker(map, marker);
        }
      }
    }
    function showMarker(map: any, marker: any) {
      if (marker.setMap()) return;
      marker.setMap(map);
    }

    function hideMarker(map: any, marker: any) {
      if (!marker.setMap()) return;
      marker.setMap(null);
    }
  }, []);

  return (
    <>
      <div ref={mapElement} style={{ width: "100%", height: "100%" }} />
    </>
  );
};

export default MapNaver;
