import React from "react";
import TitleFormat from "../../../components/TitleFormat";
import Vision from "./Vision";
import History from "./History";
const VisionHistory = () => {
  return (
    <>
      {/* <Vision /> */}
      <History />
    </>
  );
};

export default VisionHistory;
