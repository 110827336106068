import React from "react";
import "./styles/ItDecarbonizationContent.scss";
import BannerType1 from "./BannerType1";
const ItDecarbonizationContent = () => {
  const contents = [
    {
      name: "탄소중립 전략 연구",
      list: ["기업 / 건축물 / 도시 ESG 보고서", "온실가스 관련 데이터 분석 및 관리", "ESG 성과 지표", "온실가스 / 넷제로 목표 수립", "감축량 검증 체계 계획", "에너지경영 및 관리 시스템 도입", "사이트 환경 및 기후위험 평가"],
    },
    { name: "탄소 감축 방안 및 분석 / 검증 ", list: ["레트로 커미셔닝 적용", "데이터 기반 에너지 오딧", "측정 및 시뮬레이션 기반 분석", "성과 및 영향인자 분석", "넷제로 달성을 위한 관리 방안", "넷제로 성과 및 KPI 검증"] },
    { name: "탈탄소화 성과정량화", list: ["탈탄소화 진단, M&V, MRV 및 성과검증", "제로에너지빌딩 통합 솔루션", "탄소중립도시 / 그린스마트 시티", "신재생에너지 통합 컨설팅", "스마트그린 FM (유지관리)", "각종 인증 및 제 3자 검증"] },
    { name: "Scope3 탄소배출량 산출", list: ["업스트림 & 다운스트림 15개 범주가치사슬 (value Chain) 전반 측정", "Scope3 감축 목표 설정", "탄소배출 고점 (hotspot) 식별", "공급망 협력 탈탄소화 전략수립"] },
    { name: "LCA 전과정평가", list: ["무해원칙 (DNSH) 기본 친환경 & 탄소감축방안 구축", "자연친화적인 천연원료 대체연구", "인간친화적인 유해물질 저감 연구", "지구친화적인 온실가스 저감 연구"] },
    { name: "저탄소 제품 및 건축자재 적용", list: ["PD (Environmental Product Declarations) 인증", "탄소배출저감 저탄소 자재평가", "GeO EPD 건축자재 연구개발 및 사업화", "지도 도료개발 완료 (국내최초 탄소 50% 절감)", "저탄소 시공프로세스 구축"] },
  ];
  return (
    <>
      <div className="it_decarbonization_content_wrap">
        <BannerType1 path="/imgs/banner_it_decarbonization.png" />
        <div className="title">
          전문적인 탈탄소화 전략 / ESG 통합솔루션
          <br />
          MRV / LCA
        </div>
        <div className="contents">
          <div className="des">연구와 실무의 체계적인 결합 및 풍부한 실적을 바탕으로 전문적인 탈탄소화 전략 / ESG 통합솔루션 / MRV / LCA 생애주기 전과정에 걸쳐 Scope3 탄소배출량 저감방안을 제공합니다.</div>
          <div className="card_wrap">
            {contents.map((content: { name: string; list: string[] }, index: number) => {
              return (
                <div className="card" key={`${content.name}_${index}`}>
                  <div className="name">{content.name}</div>
                  <ul>
                    {content.list.map((text: string, index: number) => {
                      return <li key={`${text}_${index}`}>{text}</li>;
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
          <div className="des">
            LCA(Life Cycle Assessment, 전과정평가) : 제품 및 서비스의 원료 채취부터 제조, 유통, 사용 및 폐기에 이르기까지 전과정에 걸친 환경 영향을 정량적으로 분석·평가하는 방법 무해원칙(DNSH: Do No Significant Harm) : 탄소저감시 다른 환경문제를
            야기하지 않는다는 무해 입증 원칙임 탄소저감을 위해 환경에 유해한 영향을 주는 것을 용인하지않는 원칙
          </div>
        </div>
      </div>
    </>
  );
};

export default ItDecarbonizationContent;
