import React, { useEffect, useRef, useState } from "react";
import "./styles/BannerType1.scss";
type Props = { path: string };
const BannerType1 = ({ path }: Props) => {
  const [bannerW, setBannerW] = useState(0);
  const [bannerS, setBannerS] = useState(0);
  const targetRef = useRef(null);
  const handleScroll = () => {
    if (500 < window.scrollY && window.scrollY < 1000) {
      const percent = 26 - (window.scrollY - 500) / 19.2;
      const scale = 1.1 - (window.scrollY - 500) * 0.0002;
      setBannerW(percent);
      setBannerS(scale);
    }
  };
  useEffect(() => {
    const timer = setInterval(() => {
      window.addEventListener("scroll", handleScroll);
    }, 100);
    return () => {
      clearInterval(timer);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div id="banner_wrap" ref={targetRef}>
      <p className="hidden_box" style={{ width: `${bannerW}%` }}></p>
      <div className="img_box">
        <img src={path} alt="" style={{ scale: `${bannerS}` }} />
      </div>
    </div>
  );
};

export default BannerType1;
