import React, { useCallback, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles/CustomSlider.scss";

type Props = {
  setting: {};
  children?: React.ReactNode[];
  navigation?: {
    visibility?: string;
    color?: string;
    position?: { top?: string | number; right?: string | number; bottom?: string | number; left?: string | number; transform?: string };
    pagingType?: string;
    paging?: { display?: string };
    bar?: { width?: string | number; height?: string | number };
  };
};

function CustomSlider({ setting, children, navigation }: Props) {
  const [totalIndex, setTotalIndex] = useState(0);
  const [slideIndex, setSlideIndex] = useState(0);
  let sliderRef = useRef<any>(null);
  const previous = useCallback(() => sliderRef.current.slickPrev(), []);
  const next = useCallback(() => sliderRef.current.slickNext(), []);
  const settings = {
    beforeChange: (current: any, next: any) => setSlideIndex(next),
    ...setting,
  };
  useEffect(() => {
    if (children) setTotalIndex(children.length);
  }, [children]);
  return (
    <div className="slider-container">
      {navigation?.pagingType === "type_01" ? (
        <div className="navigation_type_01_wrap" style={navigation?.position}>
          <div className="paging" style={navigation?.paging}>
            <button onClick={previous} className="arrow_previous">
              <svg className="arrow pre" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="18" cy="18" r="18" fill="#F1F1F1" />
                <path d="M19 26L11 18L19 10" stroke="#111111" />
                <path d="M11 18H25.5" stroke="#111111" />
              </svg>
            </button>
            <button onClick={next} className="arrow_next">
              <svg className="arrow next" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="18" cy="18" r="18" transform="rotate(-180 18 18)" fill="#F1F1F1" />
                <path d="M17.5 10L25.5 18L17.5 26" stroke="#111111" />
                <path d="M25.5 18L11 18" stroke="#111111" />
              </svg>
            </button>
          </div>
        </div>
      ) : (
        <div className="navigation" style={navigation?.position}>
          <div className="paging" style={navigation?.paging}>
            <button onClick={previous} className="arrow_previous">
              <svg className="arrow pre" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 6L9 12L15 18" stroke={navigation?.color} />
              </svg>
            </button>
            <span style={{ color: navigation?.color }}>{String(slideIndex + 1).padStart(2, "0")}</span>
            <span style={{ color: navigation?.color }}>/</span>
            <span>{String(totalIndex).padStart(2, "0")}</span>
            <button onClick={next} className="arrow_next">
              <svg className="arrow next" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 6L9 12L15 18" stroke={navigation?.color} />
              </svg>
            </button>
          </div>
          <div className="bar" style={navigation?.bar}>
            <p style={{ width: `${(100 / totalIndex) * (slideIndex + 1)}%`, backgroundColor: navigation?.color }}></p>
          </div>
        </div>
      )}

      <Slider ref={sliderRef} {...settings}>
        {children}
      </Slider>
    </div>
  );
}

export default CustomSlider;
