import React from "react";
import "./styles/TotalEcoContent.scss";
import BannerType1 from "./BannerType1";
const TotalEcoContent = () => {
  const content_01 = [
    { name: "1. 건축심의", list: ["에너지사용계획 협의", "일조환경분석", "경관심의", "교육환경평가"] },
    { name: "2. 사업승인(건축허가)", list: ["에너지절약형 친환경 주택", "에너지절약설계기준", "장수명주택인증", "공동주택소음방지대책 예측보고서", "범죄예방건축기준", "수질오염총량관리제", "저영향개발사전협의 (LID)", "신재생 에너지 설치"] },
    {
      name: "3. 사업승인(건축허가) 취득후",
      list: ["녹색건축 예비인증", "건축물에너지효율등급 예비인증", "제로에너지건축물 예비인증", "장애물없는 생활환경 (BF) 예비인증", "지능형건축물인증 (IBS) 예비인증", "범죄예방 환경설계인증(CPTED)", "건물에너지관리시스템(BEMS)"],
    },
    { name: "4. 입주자 모집공고 및 착공", list: ["녹색건축인증 - 주택성능등급", "공동주택 결로방지 설계기준"] },
    { name: "5. 사용승인 신청 시", list: ["에너지절약형친환경주택\n(이행확인서)", "에너지절약설계기준\n(이행확인서)", "건강친화형주택건설기준\n(이행확인서)"] },
    { name: "6. 사용검사 / 준공인가", list: ["녹색건축 본인증", "건축물에너지효율등급 본인증", "제로에너지건축물 본인증", "장애물없는생활환경(BF)본인증", "지능형건축물인증(IBS)본인증", "공동주택소음방지대책", "측정결과보고서"] },
  ];
  return (
    <>
      <div className="total_eco_content_wrap">
        <BannerType1 path="/imgs/banner_consulting_total_eco.png" />
        <div className="contents">
          <div className="g_seed_wrap">
            <div className="title">
              녹색건축물인증
              <br />
              (G-SEED) 프로세스
            </div>
            <div className="des">기획단계에서 디자인, 시공 및 운영단계에 걸친 단계별 최적의 탄소중립 프로세스를 적용하여 건축물의 환경성능을 향상시키고 각종 인센티브를 제공합니다.</div>
            <div className="img_box">
              <img id="b" src="/imgs/g_seed_process_01.png" alt="g_seed_process" />
              <img id="s" src="/imgs/g_seed_process_02.png" alt="g_seed_process" />
            </div>
          </div>
          <div className="construction_standards_wrap">
            <div className="title">
              건축기준 완화 산정 예시
              <br />및 프로세스
            </div>
            <div className="process_wrap">
              <div className="img_box">
                <img src="/imgs/construction_standards_relaxation_process.png" alt="process" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TotalEcoContent;
