import React from "react";
import "./styles/NetZeroContent.scss";
type Props = {
  title: string;
  name_color: string;
  list: { name: string; text: string }[];
};
const NetZeroContent = ({ title, name_color, list }: Props) => {
  return (
    <>
      <div className="net_zero_content_wrap">
        <h2>{title}</h2>
        <div className="content">
          {list.map((card: { name: string; text: string }, index: number) => {
            return (
              <div className="card_wrap" key={`${card.name}_${index}`}>
                <div className="name" style={{ backgroundColor: name_color }}>
                  {card.name}
                </div>
                <div className="text">{card.text}</div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default NetZeroContent;
