import React from "react";
import "./styles/Certification.scss";
import CertificationCard from "../../components/CertificationCard";
import CustomSlider from "../../components/CustomSlider";

const Certification = () => {
  //
  return (
    <>
      <div className="certification_wrap">
        <div className="left">
          <div className="title_wrap">
            <h2>인증서</h2>
            <div className="des">신뢰성 높은 기업인증과 글로벌 전문 자격 보유로 국내외 R&D와 첨단 프로젝트를 리드 합니다</div>
          </div>
          <div className="card_wrap">
            <div className="card leed_card">
              <div className="upper">특허 2건 등록, 1건 출원, 8건 출원 중 글로벌 자격 보유 현황</div>
              <div className="under">
                <div className="txt_wrap">
                  <span>LEED AP 5명 / CMVP 3명</span>
                  <span>BREEAM / GRESB / GSAS 전문가, 리뷰어 자격</span>
                  <span>BCXP / BEAP / BEMP / CP ENMS / SEP PQ</span>
                  <span>TAB / 커미셔닝 (미국)</span>
                  <span>ISO 50001 ASSESSOR</span>
                </div>
                <div className="patent_icon_wrap">
                  <img src="/imgs/icon_patent.png" alt="patent" />
                </div>
              </div>
            </div>
            <div className="card human_resources_card">
              <div className="upper">스마트한 소통과 변화에 대응하며 인재들과 함께 성장하는 기업입니다.</div>
              <div className="under">
                <div className="txt_wrap">
                  <span>국토부 / 국토안전관리원 ESG 자문위원 / ESG전문가</span>
                  <span>서울시 기후변화기금운용 심의위원 / 서울시건축사회 자문위원</span>
                  <span>WISET 기후변화대응 전문강사 / 중소기업기술 평가위원</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="right">
          <CustomSlider
            setting={{
              infinite: true,
              speed: 500,
              slidesToShow: 1,
              slidesToScroll: 1,
              autoplay: false,
              autoplaySpeed: 3000,
              arrows: false,
              draggable: false,
            }}
            navigation={{ pagingType: "type_01", color: "#111111", position: { bottom: "0px", left: "50%", transform: "translate(-50%, -50%)" } }}
          >
            <CertificationCard
              path={[
                "/imgs/certification_01.png",
                "/imgs/certification_02.png",
                "/imgs/certification_03.png",
                "/imgs/certification_04.png",
                "/imgs/certification_05.png",
                "/imgs/certification_06.png",
                "/imgs/certification_07.png",
                "/imgs/certification_08.png",
                "/imgs/certification_09.png",
              ]}
            />
            <CertificationCard
              path={[
                "/imgs/certification_10.png",
                "/imgs/certification_11.png",
                "/imgs/certification_12.png",
                "/imgs/certification_13.png",
                "/imgs/certification_14.png",
                "/imgs/certification_15.png",
                "/imgs/certification_16.png",
                "/imgs/certification_17.png",
                "/imgs/certification_18.png",
              ]}
            />
          </CustomSlider>
        </div>
        {/* <div className="bg_img">
          <img src="/imgs/bg_certification.png" alt="background_image_certification.png" />
        </div> */}
      </div>
    </>
  );
};

export default Certification;
