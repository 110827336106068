import React from "react";
import TitleFormat from "../../../components/TitleFormat";
import ItCertificationContent from "../../../components/ItCertificationContent";

const ItCertification = () => {
  const title = { text: "글로벌 인증 및 기준\nGlobal Certification &\nStandard" };
  return (
    <>
      <TitleFormat text={title.text} />
      <ItCertificationContent />
    </>
  );
};

export default ItCertification;
