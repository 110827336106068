import React, { useEffect, useState } from "react";
import "./styles/AboutUs.scss";
import VisionHistory from "./History/VisionHistory";
import Ci from "./Ci/Ci";
import PageTemplate from "../../components/PageTemplate";
import Ceo from "./Ceo/Ceo";
import Business from "./Business/Business";
import Vision from "./Vision/Vision";

const AboutUs = () => {
  const [tap, setTap] = useState(0);
  return (
    <>
      <div id="aboutus_wrap">
        <PageTemplate title="회사소개" menuList={["사업소개", "인사말", "비전", "연혁", "CI"]} tap={tap} setTap={setTap} backgroundImage="/imgs/bg_aboutus" />
        <div className="content">
          {(() => {
            switch (tap) {
              case 0:
                return <Business />;
              case 1:
                return <Ceo />;
              case 2:
                return <Vision />;
              case 3:
                return <VisionHistory />;
              case 4:
                return <Ci />;
              default:
                return <Business />;
            }
          })()}
        </div>
      </div>
    </>
  );
};

export default AboutUs;
