import React, { useState } from "react";
import PageTemplate from "../../components/PageTemplate";
import DesignSolution from "./DesignSolution/DesignSolution";
import DesignCity from "./DesignCity/DesignCity";
import DesignGallery from "./DesignGallery/DesignGallery";

const Design = () => {
  const [tap, setTap] = useState(0);
  return (
    <>
      <PageTemplate title="디자인" menuList={["ESG 디자인 솔루션", "도시·토목·생태·스마트경관", "갤러리"]} tap={tap} setTap={setTap} backgroundImage="/imgs/bg_design" />
      <div className="design_wrap" style={{ maxWidth: 1920, width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
        {(() => {
          switch (tap) {
            case 0:
              return <DesignSolution />;
            case 1:
              return <DesignCity />;
            case 2:
              return <DesignGallery />;
            default:
              return <DesignSolution />;
          }
        })()}
      </div>
    </>
  );
};

export default Design;
