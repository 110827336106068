import React from "react";
import TitleFormat from "../components/TitleFormat";
import ItEnvironmentContent from "../components/ItEnvironmentContent";

const ItEnvironment = () => {
  const title = { text: "환경성능 컨설팅\nSimulation & Consulting" };

  return (
    <>
      <TitleFormat text={title.text} />
      <ItEnvironmentContent />
    </>
  );
};

export default ItEnvironment;
