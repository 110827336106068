import React from "react";
import SaaSDtOverView from "../../../components/SaaSDtOverView";
import SaaSDtPlatform from "../../../components/SaaSDtPlatform";

const SaaSDt = () => {
  return (
    <>
      <SaaSDtOverView />
      <SaaSDtPlatform />
    </>
  );
};

export default SaaSDt;
