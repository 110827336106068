import React, { useEffect, useState } from "react";
import PageTemplate from "../../components/PageTemplate";
import SmartGreen from "./SmartGreen/SmartGreen";
import SaaSDt from "./SaaSDt/SaaSDt";
import TestBed from "../TestBed";
import { MoveToTop } from "../../hooks/MoveToTop";

const DigitalTwin = () => {
  const [tap, setTap] = useState(0);
  useEffect(() => {
    MoveToTop();
  }, []);
  return (
    <>
      <PageTemplate title="디지털전환DT" menuList={["스마트그린ESG기술", "SaaS기반DT플랫폼", "테스트베드&스페이스"]} tap={tap} setTap={setTap} backgroundImage="/imgs/bg_digitaltwin" />
      <div className="digital_twin_wrap" style={{ maxWidth: "1920px", width: "100%" }}>
        {(() => {
          switch (tap) {
            case 0:
              return <SmartGreen />;
            case 1:
              return <SaaSDt />;
            case 2:
              return <TestBed />;
            default:
              return <SmartGreen />;
          }
        })()}
      </div>
    </>
  );
};

export default DigitalTwin;
