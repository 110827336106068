import React from "react";
import "./styles/ItCertificationContent.scss";
import BannerType1 from "./BannerType1";
const ItCertificationContent = () => {
  const contents = [
    { path: "/imgs/certification_leed.png", name: "LEED 인증", text: "설계, 시공, 운영 등 건물에 생애주기의 모든 단계에 대한 건물의 친환경 및 지속성을 평가하는 친환경 건축물 인증제도 LEED ZERO 및 LEED Cities and Community" },
    { path: "/imgs/certification_well.png", name: "WELL 인증", text: "인체의 건강과 복지에 대해 중점적으로 평가하는 세계 최조(2014년)의 건물 인증 제도" },
    { path: "/imgs/certification_breeam.png", name: "BREEAM 인증", text: "세계 최초의 건축물 환경영향평가 시스템 유럽에서 다양한 친환경 정부기관 및 단체와 공동 연구하며 발전" },
    {
      path: "/imgs/certification_gresb.png",
      name: "GRESB 인증",
      text: "Global Real Estate Sustainability Benchmark는 부동산 실물 자산과 그 운용사를 대상으로 환경과 사회에 미치는 영향, 이해관계자의 관계 등을 종합적으로 평가하는 세계최고 권위의 ESG 평가 인증 제도",
    },
    { path: "/imgs/certification_gsas.png", name: "GSAS 인증", text: "카타르의 친환경 건축물 인증제도" },
    { path: "/imgs/certification_tcfd.png", name: "TCFD 체계 ", text: "기후 관련 재무 정보 공개를 권장하는 프레임워크 투자자들이 이를 바탕으로 더 나은 의사 결정을 할 수 있도록 지원" },
    {
      path: "/imgs/certification_eu_taxonomy.png",
      name: "EU Taxonomy 체계",
      text: "유럽 연합이 지속 가능성을 평가하기 위해 개발한 분류 체계. 경제 활동이 환경적으로 지속 가능한지 여부를 평가하고, 투자자들이 친환경 프로젝트에 자금을 투자할 수 있도록 정보를 제공",
    },
    { path: "/imgs/certification_re100.png", name: "RE100 이니셔티브", text: "기업이 100% ‘재생 가능 에너지로 전환할 것을 목표로 하며 기후 변화 대응에 기여하도록 독려하는 글로벌 이니셔티브" },
    { path: "/imgs/certification_ctcn.png", name: "CTCN 네트워크", text: "유엔 기후 변화 협약(UNFCCC) 하에 설립된 기후 기술 지원 네트워크. 개발도상국이 기후 변화 대응 및 적응 기술을 도입할 수 있도록 기술 지원, 정보 제공, 역량 강화를 지원" },
    { path: "/imgs/certification_sdm.png", name: "SDM 메커니즘", text: "파리협정에 따라 탄소 배출을 줄이기 위한 메커니즘. 지속 가능한 개발 프로젝트를 통해 탄소 배출을 감축하고, 이를 통해 얻은 탄소 크레딧을 거래할 수 있게 지원" },
    { path: "/imgs/certification_gri.png", name: "GRI 기준", text: "기업의 지속 가능성 보고 기준 (환경, 사회, 경제적 영향 투명 보고)을 설정하는 글로벌 표준" },
    { path: "/imgs/certification_sasb.png", name: "SASB 기준", text: "산업별로 지속 가능성 관련 재무 정보를 표준화하여 보고하는 기준. 투자자와 기업이 재무적으로 중요한 지속 가능성 정보를 이해하고 비교할 수 있도록 지원" },
    { path: "/imgs/certification_un_sdg.png", name: "UN SDG’s 목표", text: "2030년까지 달성해야 할 17개의 지속 가능한 개발 목표를 제시. 빈곤 퇴치, 기후 변화 대응, 평등 증진 등 다양한 글로벌 이슈를 해결하기 위한 국제적 노력을 반영" },
    { path: "/imgs/certification_ifrs.png", name: "IFRS S1 / S2 / S3 기준", text: "지속 가능성 관련 재무 보고를 위한 국제 회계 기준. 환경, 사회, 거버넌스(ESG) 요소를 재무 보고에 포함하여 투명성과 책임성 강조" },
  ];

  return (
    <>
      <div className="it_certification_content_wrap">
        <BannerType1 path="/imgs/banner_it_certification.png" />
        <div className="title">글로벌 인증</div>
        <div className="contents">
          <div className="des">GRI, GRESB, LEED, WELL, BREEAM, SASB, UNSDG’s, TCFD, RE100, CTCN, ISSB / KSSB 기반 ESG(EU IFRS S1, S2, 택소노미 / 1호, 2호, 101호, TNFD반영 통합탄소회계), SDM</div>
          <div className="card_wrap">
            {contents.map((content: { path: string; name: string; text: string }, index: number) => {
              return (
                <div className="card" key={`${content.name}_${index}`}>
                  <div className="img_box">
                    <img src={content.path} alt={content.name} />
                  </div>
                  <div className="text_wrap">
                    <div className="name">{content.name}</div>
                    <div className="text">{content.text}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ItCertificationContent;
