import React, { useEffect, useRef, useState } from "react";
import "./styles/AcademyContent.scss";
import { academy } from "../utils/academy";
import CustomSlider from "./CustomSlider";
import BannerType1 from "./BannerType1";
const AcademyContent = () => {
  const [content, setContent] = useState<any[][]>([]);
  useEffect(() => {
    const count = 28;
    const result = [];
    for (let i = 0; i < academy.length / count; i++) {
      result.push(academy.slice(i * count, i * count + count));
    }
    setContent(result);
  }, [academy]);

  return (
    <>
      <div id="academy_content_wrap">
        <BannerType1 path="/imgs/banner_aboutus_academy.png" />
        <div className="title">
          아카데미
          <br />
          GeO Academy
        </div>
        <div className="contents">
          <CustomSlider
            setting={{
              infinite: true,
              speed: 500,
              slidesToShow: 1,
              slidesToScroll: 1,
              autoplay: false,
              autoplaySpeed: 3000,
              arrows: content.length > 1 ? true : false,
              draggable: false,
            }}
            navigation={{ pagingType: "type_01", color: "#111111", position: { bottom: "-100px", left: "50%", transform: "translate(-50%, -50%)" } }}
          >
            {content.map((dataArray: { path: string; name: string; date: string }[], index: number) => {
              return (
                <div className="card_wrap" key={`${dataArray[index].name}_${index}`}>
                  {dataArray.map((data: { path: string; name: string; date: string }, index2: number) => {
                    return (
                      <div className="card" key={`${data.name}_${index2}`}>
                        <div className="img_box">
                          <img src={`${data.path}`} alt={`${data.name}`} />
                        </div>
                        <div className="name">{data.name}</div>
                        <div className="date">{data.date}</div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </CustomSlider>
        </div>
      </div>
    </>
  );
};

export default AcademyContent;
