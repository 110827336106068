export const academy = [
  { path: "/imgs/academy_example_01.png", name: "건축음향", date: "23.02.28" },
  { path: "/imgs/academy_example_02.png", name: "유리 재활용 친환경 외장재", date: "23.02.21" },
  { path: "/imgs/academy_example_03.png", name: "철골 구조 프로그래밍, 지하ㆍ토목안전 업무", date: "23.02.21" },
  { path: "/imgs/academy_example_04.png", name: "디지털 건설사업관리", date: "23.02.14" },
  { path: "/imgs/academy_example_05.png", name: "국내외 그린빌딩인증의 환경성능표지(EPD) 평가방법", date: "23.02.08" },
  { path: "/imgs/academy_example_06.png", name: "How ESG? BSC + ESG", date: "23.02.08" },
  { path: "/imgs/academy_example_07.png", name: "복사 냉난방 시스템", date: "23.02.07" },
  { path: "/imgs/academy_example_08.png", name: "BIM 모델링을 활용한 구조 수량산출", date: "23.01.31" },
  { path: "/imgs/academy_example_09.png", name: "HEMS 소개 및 사례", date: "23.01.24" },
  { path: "/imgs/academy_example_10.png", name: "탄소중립과 연료전지(SOFC) 산업", date: "23.01.16" },
  { path: "/imgs/academy_example_11.png", name: "제로에너지빌딩 시스템구축(BEMS) 가이드", date: "23.01.13" },
  { path: "/imgs/academy_example_12.png", name: "기업 탄소중립을 위한 RE100 동향", date: "23.01.10" },
  { path: "/imgs/academy_example_13.png", name: "건축물 에너지효율등급 ZEB 인증", date: "23.01.10" },
  { path: "/imgs/academy_example_14.png", name: "경량삼복층ㆍ스마트 윈도우", date: "23.01.04" },
  { path: "/imgs/academy_example_15.png", name: "조명디자인", date: "23.12.14" },
  { path: "/imgs/academy_example_16.png", name: "컬러 BIPV", date: "23.12.14" },
  { path: "/imgs/academy_example_17.png", name: "인문 건축 기행", date: "23.12.11" },
  { path: "/imgs/academy_example_18.png", name: "디지털트윈 및 딥러닝 기반 에너지 환경 패턴 예측", date: "23.12.07" },
  { path: "/imgs/academy_example_19.png", name: "블루수소와 탄소포집기술", date: "23.12.07" },
  { path: "/imgs/academy_example_20.png", name: "BIPV 유리성능 및 도어시스템", date: "23.11.30" },
  { path: "/imgs/academy_example_21.png", name: "고성능 창호/PVC 창호", date: "23.11.16" },
  { path: "/imgs/academy_example_22.png", name: "LEED, WELL 인증 항목별 문제점 및 솔루션", date: "23.11.09" },
  { path: "/imgs/academy_example_23.png", name: "신재생에너지를 활용한 탄소중립 커뮤니티 모델", date: "23.10.26" },
  { path: "/imgs/academy_example_24.png", name: "AI : 보안시큐리티", date: "23.10.19" },
  { path: "/imgs/academy_example_25.png", name: "DEMC 특수필터", date: "23.10.12" },
  { path: "/imgs/academy_example_26.png", name: "지구환경을 생각하는 디자인 pipe & pipes", date: "23.10.05" },
  { path: "/imgs/academy_example_27.png", name: "eco-film 일체형 Switch Window Technology", date: "23.10.05" },
  { path: "/imgs/academy_example_28.png", name: "Architecture & Computation_공간배치 자동설계", date: "23.09.27" },
  { path: "/imgs/academy_example_29.png", name: "Zyx CAD", date: "23.09.21" },
  { path: "/imgs/academy_example_30.png", name: "차열도료", date: "23.09.14" },
  { path: "/imgs/academy_example_31.png", name: "세라믹 스톤/타일 내외장재", date: "23.08.17" },
  { path: "/imgs/academy_example_32.png", name: "알루미늄 시스템 윈도우 & 파사드 기술", date: "23.08.03" },
  { path: "/imgs/academy_example_33.png", name: "토양정화사업", date: "23.07.06" },
  { path: "/imgs/academy_example_34.png", name: "데어슈츠 회사소개 및 주요 제품, 솔루션", date: "23.06.29" },
  { path: "/imgs/academy_example_35.png", name: `친환경 수성 방염 코팅제 "NoFire" 소개`, date: "23.06.22" },
  { path: "/imgs/academy_example_36.png", name: "해외 친환경 인증소개", date: "23.06.08" },
  { path: "/imgs/academy_example_37.png", name: "인간중심조명 제품 트렌드-HCL조명 시스템", date: "23.06.08" },
  { path: "/imgs/academy_example_38.png", name: "에너지절감, 효율화 기술 및 플랫폼", date: "23.06.08" },
  { path: "/imgs/academy_example_39.png", name: "도시 공간 녹화", date: "23.06.01" },
  { path: "/imgs/academy_example_40.png", name: "친환경 건축자재(팰렛)", date: "23.05.18" },
  { path: "/imgs/academy_example_41.png", name: "ESG 평가 프로그램", date: "23.05.11" },
  { path: "/imgs/academy_example_42.png", name: "탄소배출권(CER) 거래", date: "23.05.04" },
  { path: "/imgs/academy_example_43.png", name: "통합배관 시스템", date: "23.04.27" },
  { path: "/imgs/academy_example_44.png", name: "캐스케이드 시스템", date: "23.04.27" },
  { path: "/imgs/academy_example_45.png", name: "BIM 프로젝트", date: "23.04.27" },
  { path: "/imgs/academy_example_46.png", name: "파사드(숨쉬는 창) 설계", date: "23.04.20" },
  { path: "/imgs/academy_example_47.png", name: "IAQ(실내공기질) 개선 필터", date: "23.04.17" },
  { path: "/imgs/academy_example_48.png", name: "집광채광 루버시스템", date: "23.04.13" },
  { path: "/imgs/academy_example_49.png", name: "탄소중립을 향한 콘덴싱 가스직화식 온수보일러", date: "23.04.06" },
  { path: "/imgs/academy_example_50.png", name: "솔라시스템 루버, BIPV", date: "23.03.30" },
  { path: "/imgs/academy_example_51.png", name: "웰인증 기반 IoT 실내공기질 측정 및 관리 시스템 개발", date: "23.03.30" },
  { path: "/imgs/academy_example_52.png", name: "EPD건축자재 인증의 이해", date: "23.03.23" },
  { path: "/imgs/academy_example_53.png", name: "Cloud BEMS 및 통합관제플랫폼 프로그램", date: "23.03.20" },
  { path: "/imgs/academy_example_54.png", name: "물관리 탄소중립형 수처리장치", date: "23.03.13" },
  { path: "/imgs/academy_example_55.png", name: "건축법규 검토 프로그램", date: "23.03.06" },
  { path: "/imgs/academy_example_56.png", name: "에너지관리 프로그램", date: "23.03.06" },
];
