import React, { useState } from "react";
import TitleFormat from "../../../components/TitleFormat";
import ItDecarbonizationContent from "../../../components/ItDecarbonizationContent";
import ItCarbonNeutralCity from "../../../components/ItCarbonNeutralCity";

const ItDecarbonization = () => {
  const title = { text: "탈탄소화 컨설팅\nDecarbonization Consulting", path: "", width: 800 };
  return (
    <>
      <TitleFormat text={title.text} />
      <ItDecarbonizationContent />
      <ItCarbonNeutralCity />
    </>
  );
};

export default ItDecarbonization;
