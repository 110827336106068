import React from "react";
import "./styles/BusinessInfoType02.scss";
import { Navigate, useNavigate } from "react-router-dom";
import BtnViewMore from "../../components/BtnViewMore";
const BusinessInfoType02 = () => {
  const navigate = useNavigate();
  const handleBtn = (e: any) => {
    const { to, tap } = contents[Number(e.currentTarget.id)];
    if (tap !== -1) {
      navigate(to, { state: { tap } });
    } else window.open("https://www.geosmartspace.com");
  };
  const contents = [
    {
      to: "/design",
      tap: 0,
      path: "/imgs/bg_home_business_info_solution.png",
      name: "솔루션 제안",
      des: "RE100 / CF100 탈탄소 / 자연채광시스템 / 수열시스템 지열 : 밀폐형, 개방형, 하이브리드 / 태양광 / 태양열 : 도입분석, BIPV 연료전지 : PME / SOFC",
    },
    { to: "", tap: -1, path: "/imgs/bg_home_business_info_smarthall.png", name: "스마트홀", des: "IT 전문가들이 창의적이고  미래적인 비젼으로 설계하였고, 사용 용이한 콘트롤시스템과 통합 장비들을 직접 구축하였습니다." },
  ];
  return (
    <>
      <div id="business_info_type_02_wrap">
        <div className="title_wrap">
          <div className="title">
            기후위기로 탄소중립의 의무는 <br /> 이미 도래한 현실입니다
          </div>
        </div>
        <div className="content">
          <div className="sub_title">2050 탄소중립 탄소를 배출한 만큼 감축 (저감, 흡수, 포집, 상쇄)하는 대책을 세워, 실질적인 탄소배출량을 ‘0’으로 만드는 목표</div>
          <div className="card_wrap">
            {contents.map((content: { path: string; name: string; des: string }, index: number) => {
              return (
                <div id={String(index)} className="card" key={`${content.name}_${index}`}>
                  <div className="img_box">
                    <img src={content.path} alt={content.name} />
                  </div>
                  <div className="name">{content.name}</div>
                  <div className="des">{content.des}</div>
                  <BtnViewMore id={String(index)} onClick={handleBtn} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessInfoType02;
