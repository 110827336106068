import React from "react";
import TitleFormat2 from "../../../components/TitleFormat2";
import TrackRecordClientContent from "./TrackRecordClientContent";

const TrackRecordClient = () => {
  return (
    <>
      <div className="track_record_client_wrap" style={{ maxWidth: "1920px", width: "100%", paddingBottom: "20%" }}>
        <TitleFormat2 text="클라이언트" />
        <TrackRecordClientContent />
      </div>
    </>
  );
};

export default TrackRecordClient;
