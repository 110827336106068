import React from "react";
import "./styles/Vision.scss";
import TitleFormat from "../../../components/TitleFormat";
const Vision = () => {
  const title = "Mission, Vision & Core Value \n미션, 비전 & 핵심가치";
  const titleWidth = 768;
  return (
    <>
      <div id="vision_wrap">
        <TitleFormat text={title} />
        <div className="content">
          <img src="/imgs/aboutus_vision.png" alt="" />
        </div>
      </div>
    </>
  );
};

export default Vision;
