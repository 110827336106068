import React from "react";
import "./styles/RepresentativePerformanceNonResidence.scss";

const RepresentativePerformanceNonResidence = () => {
  const contents = [
    {
      name: "강릉시 경포지구 근린생활시설",
      path: "/imgs/trackrecord_residence_01.png",
    },
    {
      name: "국립진주박물관",
      path: "/imgs/trackrecord_residence_03.png",
    },
    {
      name: "이화여자대학교 생활환경관",
      path: "/imgs/trackrecord_residence_04.png",
    },
    {
      name: "오시리아 호텔 & 리조트",
      path: "/imgs/trackrecord_residence_05.png",
    },
    {
      name: "마곡 R&D 센터",
      path: "/imgs/trackrecord_residence_08.png",
    },
    {
      name: "잠실 스포츠 MICE 복합공간 외 복합단지 다수",
      path: "/imgs/trackrecord_non_residence_01.png",
    },
    {
      name: "강원 강릉시 저동 호텔 야간경관 계획",
      path: "/imgs/trackrecord_non_residence_02.png",
    },

    {
      name: "부천 내동 데이터센터 외 데이터센터 다수",
      path: "/imgs/trackrecord_non_residence_04.png",
    },
    {
      name: "상암 디지털 미디어 시티 복합쇼핑몰 외",
      path: "/imgs/trackrecord_non_residence_05.png",
    },
    {
      name: "UAE 아부다비 히타치 변전소 외 해외사업 다수",
      path: "/imgs/trackrecord_non_residence_06.png",
    },
    {
      name: "에코앤로지스 부산 물류센터",
      path: "/imgs/trackrecord_non_residence_07.png",
    },
    {
      name: "사학연금 서울회관",
      path: "/imgs/trackrecord_non_residence_08.png",
    },
    {
      name: "제천시 기회발전 특구",
      path: "/imgs/trackrecord_non_residence_09.png",
    },
    {
      name: "수자원공사 물 안심공간조성 등 공공기관 프로젝트 다수",
      path: "/imgs/trackrecord_non_residence_10.png",
    },
    {
      name: "바람길 분석",
      path: "/imgs/trackrecord_non_residence_11.png",
    },
    {
      name: "전과정 평가 (LCA)를 통한 탄소중립 외 다수",
      path: "/imgs/trackrecord_non_residence_12.png",
    },
  ];
  return (
    <>
      <div className="representative_performance_non_residence_wrap">
        <div className="title">비주거부문</div>
        <div className="content">
          {contents.map((content: { name: string; path: string }, index: number) => {
            return (
              <div className={`card card_${index}`} key={`${content.name}_${index}`}>
                <div className="img_box">
                  <img src={content.path} alt={content.name} />
                </div>
                <div className="name">{content.name}</div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default RepresentativePerformanceNonResidence;
