import React from "react";
import "./styles/CeoOrganizationChart.scss";

const CeoOrganizationChart = () => {
  return (
    <>
      <div id="ceo_organization_chart_wrap">
        <div className="title">
          Organization Chart <br />
          조직도
        </div>
        <div className="content">
          <div className="img_box">
            <img src="/imgs/ceo_organization_chart.png" alt="organization_chart_image" />
          </div>
        </div>
      </div>
    </>
  );
};

export default CeoOrganizationChart;
