export const news = [
  {
    path: "/imgs/news_thumbnail_01.png",
    title: "SDX 재단, 탄소감축량 기반 우수사례 공모전 수상작 발표",
    date: "2024. 05. 02 ",
    des: `
SDX재단(이사장 전하진)은 탄소감축 기후기술 육성과 기후행동 확산을 위해 3월6일부터 한달간 국내에서 처음으로 탄소감축량 기반 우수사례 공모전을 진행했으며 1주의 심사기간을 거쳐 최근 공모전 수상작을 발표했다고 밝혔다. 

이번 공모전은 시민, 지자체 및 기업들을 대상으로 탄소감축량을 정량적으로 제시할 수 있는 탄소감축 활동사례를 발굴해 탄소중립에 대한 공감대를 확산하는 한편 탄소감축에 대한 사회적인 실천 분위기를 확산하고자 SDX탄소감축인증센터 주관으로 진행됐다.

최우수 수상작은 에스앤아이코퍼레이션(조용범, 오현우, 오세영)의 ‘에스앰아이코퍼레이션과 함께 하는 탄소감축 활동’이 선정돼 환경부장관상 수상의 영광을 차지했다. 벤처기업협회장상은 아키테코그룹(여태한, 심현숙)의 ‘실내공기질 모니터링시스템과 스마트 그린ESG 플랫폼’이, 한국환경경영학회장상은 제주특별자치도(현광민)의 ‘탄소중립 선도도시, 제주에서의 탄소감축 우수사례’와 한국금거래소(최창익)의 ‘재생금 생산으로 인한 탄소감축사례’가 각각 수상의 영예를 안았다. 

또한 △한국남동발전의 탄소감축사례(한국남동발전) △현대자동차 아산공장 급탕방식 변경 및 스팀라인 개선을 통한 탄소감축(현대자동차) △국립산림과학원의 사방댐 재료변경에 따른 탄소감축사례(개인 참가자 권순길씨) 등이 SDX재단 이사장상 수상작으로 선정됐다. 

환경부장관상을 수상한 에스앤아이코퍼레이션은 건물의 전력사용량을 중심으로 탄소를 감축했으며 마곡, 분당, 양재 등에 위치한 다점포매장을 대상으로 약 3,762톤의 온실가스 감축량을 기록한 사례를 공유했다. 

이와 함께 향후 서울시 경로당 및 지방 소재 건물까지 이러한 사례를 확장할 계획을 발표해 최우수 사례로 선정됐다. 벤처기업협회장상을 수상한 아키테코그룹은 스마트오피스에 적용할 수 있는 실내공기질 모니터링시스템을 개발하고 적용해 약 171톤의 온실가스를 감축했다. 스마트그린, ESG플랫폼 개발로 약 2,000톤의 온실가스 감축 사례를 제시해 수상작이 됐다. 

탄소감축 우수사례 공모전 시상식 및 우수사례 발표는 오는 4월24일 HW컨벤션센터에서 개최되는 ‘리월드포럼 2024’에서 진행될 예정이다. 

김준범 SDX탄소감축인증센터장은 “이번 공모전 시상식이 탄소감축 확산모델을 제시해 우리나라 탄소중립 목표 달성에 기여할 수 있는 의미있는 행사가 될 것으로 기대한다”고 밝혔다. 

한편 SDX재단은 탄소중립에 대한 사회적 인식을 제고하기 위해 리월드포럼, 탄소중립 우수사례 공모전 등을 매년 개최할 예정이다. 이를 통해 기후위기 극복을 위한 탄소중립 및 ESG 캠페인을 전개할 계획이다. 

저작권자 2015.10.01 ⓒ Kharn`,
  },
  {
    path: "/imgs/news_thumbnail_02.png",
    title: "건물 넷제로화 韓-英 국제연구 진행",
    date: "2024. 04. 28 ",
    des: `
국토교통부가 주관한 한·영 양자협력형 국제공동연구개발사업인 ‘건물 커뮤니티 맞춤형 신재생에너지 기반 넷제로 에너지 최적화 솔루션 개발과 사업화’에 고려대, 중앙대, 아키테코그룹, 미코파워가 공동 연구기관으로 최종 선정됐다. 

이번 국책과제는 한·영연구단의 국제 협력체계를 기본으로 글로벌 기술경쟁력 강화 및 글로벌 기술협력거점 구축을 위해 진행되며 허연숙 고려대 산·학협력단 교수가 주관을, 중앙대, 아키테코그룹, 미코파워가 공동 연구기관으로 참여한다.  

이번 과제 목표는 △건물 커뮤니티 넷제로 달성을 위한 신재생에너지 네트워크 설계 및 운영 최적화 솔루션 도출 프로그램(SoftwareECom) 개발 △연료전지 운영 최적화 모듈, 신재생에너지 네트워크 설계 최적화 모듈 등과 연계되는 SOFC(고체 산화물 연료전지) 시스템 제품 개발 및 실증 등이다. 영국 연구단에서는 Data Clarity Limited의 Ben Atack 주관으로 노팅엄대학과 A.R.C Retrofic Solution이 공동 연구기관으로 참여한다.  

지구온난화로 인한 점진적인 기온 상승을 억제하고자 하는 탈탄소화에 대한 중요성은 이미 모두가 알고 있는 사실이다. 이중 건축물에서의 넷제로 에너지 최적화 문제는 시급히 해결돼야 하는 숙제다. 전 세계 온실가스 배출의 약 37%는 건축물에서 발생하며 이는 항공, 육상, 해상 운송부문의 배출량 합의 2배에 달하는 양이다. 

기후 위기 심각성을 고려한다면 건축물의 효율적인 넷제로 에너지 운영과 탈탄소화 전환은 필수적이다. 이에 따라 도시의 지속가능성 확보를 위해 넷제로 에너지 달성 목표가 국제적으로 주요 의제 중 하나로 대두되면서 국제에너지기구는 도시 내 신재생에너지시스템 확충과 디지털 기술 기반 최적 운영을 핵심 전략으로 제시한 바 있다. 특히 대한민국은 넷제로 에너지 건물 및 도시의 실현은 에너지 자원 고갈 문제 및 에너지 의존도 해결에 필수적인 상황이다.

또한 세계 곳곳에서 ESG(지속가능성) 공시의 주요 기준과 규범이 적용되기 시작하는 등 기후공시 의무화 일정이 본격화되고 있으며 국내에서도 ESG 공시 의무화제도가 단계적으로 진행될 예정이다. ZEB(제로에너지빌딩인증제도) 도입 등 친환경 건축물 전환 가속화에 따른 넷제로 에너지 최적화 필요성 및 신재생에너지의 역할은 더욱더 가중될 전망이다. 

이에 따라 신재생에너지 기반 에너지공급 네트워크를 구축하기 위해서는 커뮤니티 에너지소비 특성, 신재생에너지 잠재량을 고려한 신재생에너지 시스템 설계 및 운영 최적화와 열·전력·수소를 종합적으로 활용해 도시 넷제로 달성도를 극대화할 수 있는 복합 신재생에너지원의 최적화된 설계 및 운영이 필요하다. 

이번 연구개발 과제의 최종목표인 신재생에너지 네트워크 설계·운영 최적화 모듈 개발로 건물 커뮤니티의 발전량 및 신재생에너지 이용률의 최대화가 가능하므로 에너지 수요처의 보급망 의존도를 낮춰 도시부문 신재생에너지 보급률 및 에너지자립률을 높이는데 기여할 것으로 예상된다. 또한 열·전력 부분부하 대응이 가능한 최적 운영전략과 연계되는 SOFC시스템 개발을 통해 운전 최적화와 운영 방안을 도출해 에너지슈퍼스테이션, 국내 미니 수소도시 건립 확대 활용 등 넷제로 실현의 비약적인 도약이 예상된다. 

미코파워의 관계자는 이번 국책과제를 통해 “수소 혼입 연료전지 실증, 연료전지 부분부하 운전 실증 등 고객니즈에 부합된 전력 및 열수요를 반영한 연료전지 실증을 통해 국내 연료전지 시스템의 넷제로 활용 가속화와 수익 창출형 에너지원으로 활용하는 비즈니스 모델을 제시할 예정”이라며 “특히 이번 과제를 통해 유럽 CE 기반 연료전지 설계와 시스템 최적화를 실현하는 등 개발기술 성과를 글로벌시장 진출에 활용해 국산 SOFC 연료전지의 수출 확대에 앞장서겠다”고 강조했다. 

강은철 기자 eckang@kharn.kr
저작권자 2015.10.01 ⓒ Kharn
`,
  },
  {
    path: "/imgs/news_thumbnail_03.png",
    title: "연세미디어 [동문이야기] 만나고싶었습니다 - 고정림 아키테코그룹",
    date: "2023. 11. 01 ",
    des: `
지구 온난화로 인해 2050년 지구가 멸망 할 것이라는 이야기가 나오고 있다. 기후 문제는 더이상 소설 속의 이야기가 아니라 바로 눈앞에 닥친 현실이 되었다.
전 세게가 기후위기에 대응하기 위해 온실 가스 감축을 위해 힘쓰고 있다. 우리나라 역시 2050 탄소중립을 선언하며 2050년까지 탄소 중립을 실천하기 위해 많은 노력을 하고 있다. ‘지구를 지키자’ 라는 목표를 가지고 아키테코그룹을 설립해 넷제로 실현을 위해 힘쓰고 있는 고정림 동문(건축88입)을 만나 이야기를 들어 보았다.

간단한 소개 부탁드립니다.
“안녕하세요? (주) 아키테코그룹 대표이사 건축 88학번 고정림 입니다”
고 동문은 모교에서 박사과정까지 마쳤고, 코넬대(미)에서 석사, 고려대 - Kegde(프) 경영대학원에서 석박사 를 취득하였으며 ‘연세대 미래여성지도자 100인’에 들기도 했다.

아키테코는 어떤 기업인지 설명 부탁드립니다.
“국가비전으로 탄소중립, 녹색성장이 정해지면서 제로에너지빌딩과 그린리모델링 등이 녹색건축업계의 최대 이슈로 부각되고 있는데요. 아키테코그룹은 넷제로기반 ‘스마트 그린 ESG 플랫폼’ 전문 벤처 기업으로, 지구환경을 디자인하고 지키는 기업미션을 가지고 차별화된 탄소중립 플랫폼으로 합리적이고도 단계적으로 토탈솔루션을 제시하는 기업입니다. 저희 회사 이념은 품위, 상생, 열정, 지속가능성으로 인재경영에 중점을 두고, 좋은 회사 만들기에 노력하고 있습니다.

넷제로 기반 ‘스마트 그림 ESG 플랫폼’ 에 대한 구체적인 설명 부탁드립니다.
“스마트 그린 ESG 플랫폼’은 AI, 블록체인, 디지털트윈 등의 첨단기술이 도입된 신개념 넷제로 실현을 위한 프로그램이자 시스템입니다. 에너지사용 관련 정보 제공에 국한된 BEMS, FEMS 등의 한계를 뛰어넘어 신축, 기존 건축물의 부지선정, 설계, 시공, 운영, 유지보수 및 자재 시스템 또는 제품의 전 생애주기(LCA)단계에 걸쳐 탄소발생량과 저감량에 대해 모니터링 하고 솔루션을 통해 통합관리가 가능토록 구현하고 있습니다.

고 동문은 ‘스마트그린 ESG 플랫폼’ 의 가장 큰 차별점은 넷제로 기술 구현을 위해 탄소사용량을 저감해나가는 다양한 방법을 사용하는 것이라고 말한다.
아키테크의 차별점은 시기술과 재생에너지원이나 무탄소에너지원 관련 캠페인들의 기본 개념을 비교 분석해 어느 방향으로 가야 할지 누구보다 먼저 고민하고 글로벌 추세와 정부지침에 맞게 로드맵을 구축한다는 것입니다. 나아가 저탄소 건축자재 (EPD : Environmental Product Declarations) 의 새로운 프로세스를 구축하는 실질적인 솔루션을 제안해 기업의 가치를 높이고 넷제로 ESG 성과를 자동 산출하는 신개념 ‘ESG 플랫폼’을 개발중인데요. 단순한 IT가 아닌 1단계에서 우선 통합적인 녹색건축기술이 콘텐츠가 돼야 하는 융복합개념의 통합관리 디지털트윈이라고 할 수 있습니다.

마지막으로 연세동문들에게 인사말 전해주세요.
사람을 좋아하고 활동적인 고 동문은 총동문회는 물론 건축과 동문회, 여자총동문회에서 부회장으로 활동 중이다.
“2005년 귀국하면서부터 바로 건축과 동문회 이사로서 활동을 시작했고, SBS대학최강 골프대회 예선전에 선수로 참가하기도 했습니다. 동기회 등 모임에 꾸준히 참여하고 가까운곳에서부터 봉사를 실천 하려고 노력하고 있습니다. 특히, 연고대 최초로 88연합밴드 레드블루를 결성하여 양교 2백50여 명이 모여 기부도하고 즐거운 추억을 만들었습니다. 연세 동문들을 너무나 사랑하고 잇으며, 제삶에서 뺄 수 없는 이소중한 순간들을 앞으로도 같이 할 것입니다.
연세를 사랑하는 모든 선후배님들도 연세와 더불어 더욱 행복하시고 좋은 일들 많이 생기시실 기원드립니다.”
`,
  },
  {
    path: "/imgs/news_thumbnail_04.png",
    title: "(주)아키테코그룹 고정림 대표, 산업포장 수상",
    date: "2023. 10. 18 ",
    des: `
고정림 아키테코그룹 대표가 녹색산업·ESG경영 활성화에 기여한 공로를 인정받아 ‘산업포장’을 수훈하는 영광을 안았다. 

아키테코그룹은 환경부가 주관한 ‘2023 친환경 기술진흥 및 소비촉진 유공 정부포상 시상식’이 10월12일 코엑스 컨퍼런스룸에서 개최됐으며 이날 고정림 대표가 산업포장을 수훈했다고 밝혔다. 

1998년부터 현재까지 20여년간 건축·건설산업을 경영해 온 고정림 대표는 환경부·국토교통부 국책과제에 적극 참여해 미국의 녹색건축위원회(USGBC)의 녹색건물 인증제도 LEED(Leadership in Energy and Environmental Design)와 영국 BREEAM(Building Research Establishment’s Environmental Assessment Method)제도 등 국제 그린빌딩기준에 부합하는 녹색건축, 제로에너지 관련 최초 적용을 주도해 왔다. 

또한 녹색성장에 기반이 되는 제도수립과 건설부문 넷제로시대 변화를 주도하는 신사업모델인 ‘스마트그린·ESG 플랫폼’ 구축 벤처기업인 아키테코그룹을 창업해 친환경 산업발전에 기여한 공로를 인정받아 ‘녹색산업·ESG경영분야’에서 산업포장을 수상하게 됐다. 고 대표는 이미 과학기술부 장관상을 수상한 바 있으며 ‘연세미래여성 100인’ 수상 멤버이기도 하다. 

고 대표는 환경분야 주요분야에서 전문성을 인정받아 △서울시청 및 제주도청 녹색건축물·기후변화 자문위원 △서울시 건축사회(서울건축산업연구원) 자문위원 △국토부, 국토안전관리원, 건설산업연구원 ESG자문위원 등으로 활동하며 적극적인 녹색산업 활성화는 물론 중앙대 ESG최고위과정 대표강사로도 활약하고 있다.

현재는 벤처기업 아키테코그룹 대표이사로서 넷제로 솔루션을 제공하는 ‘스마트그린·ESG 플랫폼’을 주요사업으로 지구환경을 지키는 차별화된 탄소중립 플랫폼을 개발하고 있다.

스마트그린·ESG 플랫폼은 AI, 디지털트윈 등 첨단기술이 도입된 신개념 넷제로시스템으로, 기존의 건축물 에너지 사용량만을 측정하던 건물에너지관리시스템(BEMS)의 한계를 뛰어넘어 신축·기존 건축물의 부지선정 시점부터 기획단계, Design Phase(설계단계), Construction Phase(시공단계), Operation Phase(운영단계), 유지보수 및 폐기단계 등 전 생애주기(LCA) 단계에 걸쳐 에너지사용량과 예측량이 더욱 신뢰성있게 최적화된다는 차별점을 가진 기술혁신 프로그램이다. 이외에도 다양한 분야에서 ESG 솔루션 개발에 꾸준히 매진하고 있다.  

아키테코그룹의 핵심역량은 탄소중립 실현 방안을 모색하며 글로벌 2050 탄소중립 흐름과 국가제도 변화에 선제적으로 대응하기 위해 △ESG연구소 △ESG 디자인솔루션연구소 △Air·Water연구소 △SIT연구소 △제로에너지연구소 등 다양한 R&D센터다.

주력 사업분야는 Net Zero 기반 스마트그린 건축물 구현을 위한 △제로에너지빌딩 컨설팅·시스템 구축 △국내외 각종 친환경·에너지 관련 인증 최적화 △고도화된 친환경 엔지니어링 기술 △도시 및 건물의 지속 가능한 환경디자인 △건물성능 유지 및 향상을 위한 BEMS △에너지 오딧 △커미셔닝 △친환경 FM(Facility Management) 기계설비성능점검 등이며 건축물의 생애주기 동안에 친환경 성능유지 향상을 위한 차별화된 솔루션을 제공하고 있다. 

특히 자체 개발한 ‘ESG 평가 통합관리시스템’을 활용해 실내공기질 컨디션 등 다양한 환경요소들을 데이터 모니터링해 에너지소비·탄소배출량 감축 성과를 정량적·지속적으로 관리해준다. 이를 통해 친환경 제품 및 건축물 전 생애주기 관점에서 Scope3에 해당하는 내재 탄소배출량을 단계적으로 감축해 나가고자 연구하고 있다.
`,
  },
  {
    path: "/imgs/news_thumbnail_05.png",
    title: "아키테코그룹 - 직스테크놀로지, 스마트 그린건축 ESG 플랫폼 구축 협업",
    date: "2023. 10. 09 ",
    des: `
Net Zero기반 스마트 그린·ESG 플랫폼기업 아키테코그룹 지오디엔티와 인공지능(AI) 디지털 설계 플랫폼기업 직스테크놀로지가 9월21일 전격적인 업무협약(MOU)을 체결하며 건물부문 탄소중립 구현을 추구하기로 합의했다.

이번 협약으로 건축설계기술에 AI 디자인 시각화 기술간 융합이 이뤄질 것으로 기대된다. 아키테코그룹은 건축설계기술‧플랫폼을 시각화하기 위해 직스테크놀로지의 ZYX AI Designer 기술을 활용할 계획이다. 향후에는 플랫폼에 건축물 LCA 전과정 탄소중립 프로세스에서 나아가 '보다 아름답고 친환경적인' 건축설계플랫폼에 도달하기 위해 시스템을 구축할 방침이다.

환경 및 IT, 전문가들로 구성된 아키테코그룹은 건축 및 자재부문 탄소저감을 1단계 목표로 삼고 AI, 블록체인, 디지털트윈 등 첨단기술을 적용해 ‘스마트 그린건축·ESG 플랫폼’ 구축을 목표로 삼고 있다.

고정림 아키테코그룹 지오디엔티 대표는 “단순한 건축물에서의 탄소중립뿐만 아니라 파사드디자인 측면에서도 도시환경을 아름답게 디자인하는 글로벌 최고 수준의 미래 스마트 도시를 구현하는 것이 목표”라며 “직스테크놀로지의 솔루션은 AI, 컴퓨터 비전, 빅데이터 분석 등 첨단기술을 활용한 순수 국내산 기술이라는 큰 의미가 있으며 또한 지능형 설계 플랫폼인 ‘ZYX AI Designer’는 매우 혁신적인 기술을 구현하는 데에 중요한 역할을 할 것으로 기대된다”고 밝혔다.

고정림 대표는 이번 협약에 앞서 지난해 12월 직스테크놀로지 신개발 설계 소프트웨어인 ‘직스캐드(ZYX CAD)’를 전직원에게 공급해 국산 캐드 기술개발을 지원한 바 있다. 이와 같이 소프트웨어 보급에 기여하는 역할과 함께 탄소중립을 위한 플러그인 프로그램 개발에 앞장서는 활동에 따라 혁신기업가 탄소중립부문, ESG경영 기술혁신기업 및 인물대상 등을 수상한 바 있다.

또한 LEED AP(미국 친환경기술전문가), BREEAM Assessor(영국친환경평가사), GSAS(카타르친환경전문가)자격을 보유하고 있으며 국내 최초 실사용 건축물에 LEED 플래티넘인증을 획득토록 컨설팅한 실적도 보유하고 있다. 특히 인증획득 과정에서 외국산 건축자재를 수입해 손쉽게 접근하는 대신 국내 건축자재 및 기술을 적용할 방안을 모색함으로써 국내기술수준을 글로벌 기준으로 높여 적용하려는 노력을 지속했다.

고정림 대표는 “누구나 가는 쉬운 길보다는 조금 어렵더라도 바른 길을 선택하고자 노력하는 기업가정신을 갖고자 노력하고 있으며 아키테코그룹 지오디엔티의 스마트그린·ESG플랫폼에도 이러한 정신을 담고자 한다”라며 “이번 직스테크놀로지와의 업무협약을 비롯해 산업 각계의 최고 기술력을 보유한 다수 기업들과 MOU를 주도하고 있는 만큼 앞으로 스마트그린·ESG플랫폼에 기대와 관심을 바란다”고 강조했다. 

여인규 기자
저작권자 2015.10.01 ⓒ Kharn
    `,
  },
  {
    path: "/imgs/news_thumbnail_06.png",
    title: "아키테코그룹, 2023 대한민국 혁신기업 브랜드 대상 수상",
    date: "2023. 06. 28 ",
    des: `
[한국목재신문=이지민 기자] (주)아키테코그룹(대표 고정림)이 6월 26일 개최된 ‘2023 대한민국 혁신기업(기관/인물)·브랜드 대상’에서 ‘탄소중립 건축문화 부문 대상’을 수상했다고 밝혔다.

‘대한민국 혁신기업(기관/인물)·브랜드 대상’은 헤럴드경제, 코리아헤럴드가 주최하고 월간파워코리아(발행인 백종원)가 주관하는 시상식으로 ㈜헤럴드가 남들과 차별화된 혁신을 보인 기업, 인물 브랜드를 발굴하여 국내외 널리 알리기 위해 제정됐다.

한국프레스센터에서 진행된 이번 시상식에는 ㈜인크레더블, 경기도청 공정경제과, 대구창조경제혁신센터, 스타벅스 코리아 등 총 57개 기업/기관/인물/브랜드가 수상의 영예를 안았다. 이 행사는 김세영 아나운서의 사회, 센세이션엠에스 이승재 대표의 연주, 바리톤 김형걸‧소프라노 이소연‧가수 설연아의 축하공연으로 진행되었다.

㈜아키테코그룹/지오D&T(디자인&테크놀로지)는 기후 위기로 인한 ESG시대에 녹색건축 및 환경 산업의 변화를 주도하고 있는 ‘스마트그린·ESG플랫폼’ 전문 기업이다.

고정림 대표는 “환경·건축·IT 분야에서 15년 이상 독보적인 기술을 이루어온 3개 그룹사와 함께 새로운 관점에서 건축 입면과 내부 나아가 도시공간과 시설물에 미술기획자와 작가의 의도를 불어넣고 표출하는 새로운 시도를 모색할 것”이라고 밝혔다.

출처 : 한국목재신문(https://www.woodkorea.co.kr)
`,
  },
  {
    path: "/imgs/news_thumbnail_07.png",
    title: "(주)아키테코 그룹, 넷제로 기반 스마트 그린·ESG 플랫폼 구축",
    date: "2023. 06. 19 ",
    des: `
[스포츠서울 | 신재유기자] 탄소중립(Net-Zero)이 시급히 해결해야 할 글로벌 과제이자 우리나라 국가 비전으로 책정되면서 건축업계 및 다양한 산업 분야에서도 이를 실현하기 위해 노력하고 있는 시점에 스마트 그린 건축 문화 확산에 앞장서는 ‘아키테코 그룹’(대표 고정림)이 주목받고 있다.
고경력 환경 전문가와 IT 전문가들로 구성된 아키테코 그룹은 건축 부문 탄소 저감을 목표로 삼고 AI, 블록체인, 디지털 트윈 등 첨단 기술을 적용해 넷-제로 기반 스마트 그린·ESG 플랫폼을 구축해주는 업무를 수행한다.

이 플랫폼을 이용하면 빌딩, 공장 전용 에너지 관리 시스템인 BEMS, FEMS의 한계에서 탈피해 사업장 입지 선정부터 설계, 시공, 운영, 유지, 보수까지 전 생애주기(LCA) 단계에 걸쳐 탄소 발생량과 저감량을 최적화하며 관리할 수 있다.
2023 스포츠서울 라이프특집 혁신한국인&파워코리아에 선정된 ‘아키테코그룹’에서는 기업 소비 전력 100%를 재생 에너지로 조달하는 RE100, 사용 전력 100%를 태양력, 풍력, 수력, 지열, 원자력 발전 등 무탄소 에너지원으로 공급하는 CF100, 2030년까지 기업이 보유·임차한 차량을 전기·수소차로 100% 전환하는 EV100 등 탄소중립 관련 캠페인의 개념을 비교, 분석해 글로벌 추세와 정부 지침에 맞춰 새롭게 로드맵을 구축해준다.

또 EPD(환경성적표지) 인증을 받은 건축 자재에 대한 품질 관리 프로세스를 구축해주고 넷-제로 및 ESG 성과를 자동 산출하는 실질적인 솔루션으로 기업의 가치를 높여준다.

건축업계에서 전문성과 공신력을 확보한 아키테코그룹은 스마트그린·ESG 플랫폼의 일부 주요 기술을 신축 사옥에 적용하면서 시험해보는 테스트베드를 구축하고 있다. 이는 그동안 축적해온 다양한 친환경 건축물 프로젝트 경험과 현장 데이터, 솔루션을 바탕으로 이루어낸 것이며 이 연구를 통해 상상을 현실로 구현하는 기업의 면모를 드러냈다.

㈜아키테코그룹 지오에어연구소에서는 국제기준에 부합하는 실내공기질모니터링 및 관리시스템 개발, 지오워터연구소에서는 환경부의 통합물관리기술 보급을 목표로 K워터와 물 안심 공간 조성시범사업 과제를 수행하고 있으며, 기후 위기에 대비코자 홍수 취약성 분석 및 호우 대응 기술 관련 연구 용역을 제안하는 중이다.

한편, 기업의 사회적 책무를 다하기 위해 2022년 12월부터 한국건물에너지기술원과 함께 취약 계층이 이용하는 복지, 노숙자 시설을 대상으로 건물 에너지 성능 개선 활동을 벌이고 있다.

인재 경영, ESG 경영에 힘쓰는 고정림 대표는 “차별화된 기술과 노하우로 스마트 그린 시대를 위해 기여하겠다”고 말했다.
whyjay@sportsseoul.com    
`,
  },
  {
    path: "/imgs/news_thumbnail_08.png",
    title: "[인터뷰] 고정림 아키테코그룹 대표 단계별 넷제로 목표 실현할 것",
    date: "2023. 05. 21 ",
    des: `
“‘스마트 그린·ESG 플랫폼’은 AI, 블록체인, 디지털트윈 등의 첨단기술이 도입된 신개념 넷제로 실현을 위한 프로그램이자 시스템입니다. 넷제로 기술 구현을 위해 탄소사용량을 저감해나가는 다양한 신기술과 재생에너지원이나 무탄소에너지원은 물론 글로벌 추세와 정부지침에 맞게 플랫폼을 구축하고 있습니다. 특히 저탄소 건축자재 등 실질적인 솔루션을 제안하는 ‘ESG플랫폼’이 될 것입니다”

국가비전으로 탄소중립, 녹색성장이 정해지면서 제로에너지빌딩과 그린리모델링 등이 녹색건축업계의 최대 이슈로 부각되고 있다. 아키테코그룹은 탄소중립시대 변화를 주도하는 신사업모델인 ‘스마트 그린·ESG 플랫폼’ 전문그룹으로 설립됐으며 지구환경을 지키는 탄소중립시대에 합리적이며 실현가능토록 단계적으로 차별화된 토탈솔루션을 제시하는 기업이다.

고경력 환경전문가들과 IT팀으로 구성된 아키테코그룹은 우선 건축물부문 탄소저감을 위해 친환경건축 적용을 위한 법적요구 조건과 다양한 실내외 영향인자에 대한 상관성 분석, 다양한 프로젝트 경험을 통해 얻은 현장 데이터와 솔루션을 바탕으로 스마트그린 탄소중립 솔루션을 보급하는 ESG플랫폼을 구축하며 친환경 관련 사업변화와 혁신을 추구하고 있다. 변화의 중심에 있는 고정림 아키테코그룹 대표를 만나봤다.

■ 기업운영 철학이 있다면
좋은 사람들과 좋은 기업을 만들어 고객과 같이 성장해 나가는 것이다. 기업에서의 좋은 사람이란 전략적으로 문제해결이 가능한 사람, 함께 일하고 있는 동료들(인적자원)과 시너지를 키워 생산성을 극대화하는 사람, 그리고 기업의 가치를 높이기 위해 노력하는 사람이다.

또한 좋은 기업이란 보유 인재들이 잠재역량을 마음껏 발휘할 수 있는 자기주도적 업무환경을 만들어 함께 성장하는 기업문화를 만들어가는 기업이다. 이전 기업이 근면, 성실 기반에 지시 위주였다면 이제 더욱 중요하게 생각해야 하는 것은 통찰력을 갖춘 인재들이 창의적 사고로 스스로 알아서 일하면서 재미와 행복을 느끼며 몰입하게 하는 것이다. 이를 통해 기업비전을 다같이 실현하는 데에 핵심역량에 집중해 가치를 창출하는 기업이다. 즉 AI, GPT, 로봇 등이 등장하고 있는 4차산업혁명 속에서 급속도로 변화하는 사업환경에 유연하게 대처할 수 있는 소양을 갖춘 스마트워커를 수시로 영입하고 보유 인재들은 자신의 잠재역량을 스스로 발견할 수 있도록 여건을 제공함으로써 스마트워커로 성장할 수 있도록 이끌어 주고자 한다.

■ 건물부문 탄소중립 필요성 및 현황은
전 세계가 기후위기 대응방안인 ‘2050년 탄소중립 목표’ 달성을 위한 ‘온실가스 감축’이 화두다. 기후위기 상황은 세계 곳곳에서 일어나고 있다. 조금이라도 그 위기를 늦추는 방안으로 탄소중립은 더욱 시급할 수 밖에 없다.

올해 3월 스위스 인터라켄에서 개최된 IPCC(Intergovernmental Panel on Climate Change)총회에서는 이러한 기후위기에 대한 시급성을 강조한 '제6차 평가보고서(AR6, The Sixth Assessment Report) 종합보고서'를 만장일치로 승인했다. 보고서에서는 지구온난화를 1.5℃ 미만으로 제한하기 위한 방안들을 각 부문별로 그리고 시스템적으로 나눠 제안하고 있다. 건물부문에서도 건물의 전 과정(LCA)에 걸친 에너지효율화를 통한 사용량 감소, 시공자재의 소비량 감소, 재생에너지를 포함한 에너지원 전환과 전력화 등을 제시하고 있다.

국제적으로도 건물부문의 탄소중립을 시급하고 중요하게 추진하고 있다. The Climate Group은 RE100(Renewable Energy 100%)의 충분한 재생에너지 자체 생산이 어려운 기업에 효과적인 EP100(Energy Productivity 100%)으로 에너지 생산성을 높여 사용량을 50% 감축할 것을 선언하는 프로그램을 병행하고 있다.

건설부문은 전 세계 탄소배출량의 약 38%을 차지한다. EU와 미국 등 국제적으로 지속가능보고서 공시 시 가치사슬상의 배출량(Scope 3)를 의무화하는 규칙안을 완화안으로 한 발 물러서기는 했지만 내재탄소배출량 줄이기에 대한 관심이 고조되고 있다.

■ 최근 국가비전으로 탄소중립, 녹색성장이 발표됐는데
우리나라도 3월21일 ‘2050 탄소중립 달성과 녹색성장 실현’을 위한 기본계획을 발표하고 2030까지 국가 온실가스 배출량을 ‘2018년의 국가 온실가스 배출량대비 40% 감축’하는 것을 목표(2030 NDC)로 제시하고 보다 현실적이고 실현 가능한 세부이행 방안을 발표했다.

지난 2021년 발표했던 기본계획안과 비교해 볼 때 주목할 부분은 전체 감축목표는 40%(NDC) 그대로 유지하면서 산업부문에서는 국내 현실적인 여건을 고려해 감축목표를 14.5%에서 11.4%로 낮춘 부분이다. 경제 사회 여건과 실현가능성 등을 고려해 조정한 것으로 이해된다. 상대적으로 발전산업 등 전환부문에서는 44.4%를 45.9%로 상향하는 등 일부 조정이 있었지만 무엇보다 가장 통합적으로 온실가스 감축 효과를 볼 수 있는 부문은 건물부문이다.

ZEB인증 의무화가 시행되고 있지만 진정한 의미의 Net ZEB라기 보다는 정부시책에 부합하는 1단계 실현가능한 적정수준의 설계 및 평가기준을 제시하고 있다. 이로 인해 온실가스가 80% 이상 감축될 수는 없다고 보며 국가와 기업의 경제상황, 그리고 기술상황을 고려해 적정한 수준의 단계별 로드맵에 의한 가이드라인과 정부지원이 필요하다. 특히 재생에너지 및 다양한 탄소저감 기술개발과 보급에 더 많은 노력을 기울여야 한다.

또한 우리나라도 건축물의 전주기 LCA(Life Cycle Assessment) 관점에서 Scope 3에 해당하는 내재탄소배출량 감축이 새로운 이슈로 매우 중요해지고 있다.

■ ZEB, GR 등 녹색건축업계 최대 이슈인데
EU는 이미 건물부분 에너지효율화에 적극적인 노력을 기울여 2021년부터 모든 신축건물에 제로에너지건축(ZEB) 기준 준수를 의무화하고 있다. 또한 기축건물에 대해서는 2009년부터 매년 일정비율로 그린리모델링(GR)을 시행해 2050년까지 건물부분 온실가스를 80%까지 줄이는 계획도 시행하고 있다.    
`,
  },
  {
    path: "/imgs/news_thumbnail_09.png",
    title: "팍스경제TV ‘클로즈업 기업현장’ 인터뷰",
    date: "2023. 05. 04 ",
    des: `
진행안지민
프로그램 개요 : 클로즈업 기업현장은 세계 속의 대한민국을 만드는데 큰 역할을 하는 국내 중소기업들을 찾아
중소산업 분야의 정확한 정보를 시청자들에게 알려주고, 경쟁력 있는 비즈니스 경영과 기술을 비롯 다양하고 유익한 기업 정보를 제공하고자 한다.프로그램 구성99%의 중소기업의 힘! 일반 시청자들은 잘 모르지만

산업현장 곳곳에서 이미 세계적으로 이름을 알리고 있는 중소기업들을 찾아가 그들이 구축해 가고 있는 새로운 비전에 대해 공감하며
국내 중소기업들의 날카로운 미래 산업 예측과 청사진을 들어본다.프로그램 제작모든커뮤니케이션

https://www.paxetv.com/tv/view.html?idxno=37916 
    `,
  },
  {
    path: "/imgs/news_thumbnail_10.png",
    title: "미코파워, 건물부문 탄소중립 활성화 협력 아키테코그룹 - 에코트에너지 MOU 체결",
    date: "2023. 04. 13 ",
    des: `
에너지절감, 탄소 저감을 목표로 건물부문 탄소중립 선순환구조를 만들어가기 위해 수소전문기업과 ESG플랫폼 기업이 마음을 모았다.

미코파워와 아키테코그룹지오, 에코트에너지는 4월7일 ESG플랫폼 사업추진 3자 업무협약(MOU)을 체결했다고 밝혔다. 아키테코그룹 사옥 스마트 아카데미홀에서 진행된 협약식에서는 하태형 미코파워 대표, 고정림 아키테코그룹 대표, 정우찬 에코트에너지 대표가 참석했으며 3사가 가진 핵심역량과 기술을 바탕으로 ESG플랫폼 사업화를 위해 상호 협력과 지원을 협약했다.

이번 협약을 통해 3사는 △ESG플랫폼사업 시너지 및 시장 확대 △수소연료전지(SOFC)시스템 시장 개척 △수요처 발굴을 위한 영업, 시공, 마케팅, 기술개발 등 포괄적인 업무협력 공동 수행키로 했다.

하태형 미코파워 대표는 “기후변화대응과 관련한 다양한 환경규제와 제로에너지를 목표로 하는 국가 방향성에 맞춰 ESG플랫폼 등 다양한 분야와의 협약을 통해 탄소배출량 감축 및 탄소중립에 이바지해 나가겠다”고 밝혔다.

고정림 아키테코그룹 대표는 "탄소중립을 위해서는 모든 산업분야의 전주기 LCA(Life Cycle Assessment) 관점에서 다각도의 노력이 필요하다"라며 "쉽지않은 모든 단계별 Net-Zero 프로세스를 '스마트 그린·ESG 플랫폼'에 담아 실현가능토록 아키테코그룹/지오와 미코파워, 에코트에너지가 함께 해 나갈 것"이라고 밝혔다.

강은철 기자 eckang@kharn.kr
저작권자 2015.10.01 ⓒ Kharn
    `,
  },
  {
    path: "/imgs/news_thumbnail_11.png",
    title: "아키테코그룹, ‘스마트 그린 ESG 플랫폼’ 으로 단계별 넷제로 목표 실현",
    date: "2023. 04. 11 ",
    des: `한국건물에너지기술원 및 ㈜서르, ㈜사맛디 MOU 통한 첨단 기술 적용

고정림 대표 "다양한 분야 탄소배출량 감축 구현하는 스마트그린 기술 선도할 것"

▲아키테코그룹 사옥에 자체 브랜드명인 ‘G’와 ‘O’를 이용한 야간조명이 눈에 띄고 있다.
[에너지경제신문 김준현 기자] 탄소중립 실현에 앞장서는 ㈜아키테코그룹이 넷제로 시대가 실현 가능하도록 최근 MOU 체결 등 광폭행보를 이어가고 있다.

고정림 대표가 이끄는 아키테코그룹은 넷제로 기반 ‘스마트 그린·ESG 플랫폼’ 전문그룹으로 지구환경을 지키는 탄소중립 시대에 합리적이고 실현 가능하도록 차별화된 솔루션을 제시하는 기업이다. 

‘스마트 그린·ESG 플랫폼’은 AI, 블록체인, 디지털 트윈 등의 첨단 기술이 도입된 신개념 넷제로 시스템이다.

예로 건축물로 보자면 에너지 사용량만을 제공하던 건물에너지관리시스템(BEMS)의 한계를 뛰어넘어 신축·기존 건축물의 부지선정, 설계, 시공, 운영, 유지 보수 등 전 생애주기(LCA) 단계에 걸쳐 탄소발생량과 저감량에 대해 블록체인 기술 적용으로 차별점을 갖는다.

또한 실내공기질 컨디션 등 다양한 환경 요소들을 모니터링해 AI기술로 최적화하고 디지털 트윈 기술로 시각화해 시스템 개발 및 디지털 전환을 현실화한 ‘스마트 그린’ 기술이다.

고정림 대표는 "가장 큰 차별점은 넷제로 기술 구현을 위해 탄소사용량을 줄여나가는 다양한 건축 신기술과 재생에너지원나 무탄소에너지원 관련 RE100, CF100, EV100 등의 캠페인들의 기본 개념을 비교 분석해 글로벌 추세와 정부지침에 맞게 로드맵을 구축하는 것이다"고 말했다.

이어 "나아가 EPD 자재의 새로운 프로세스를 구축하는 실질적인 솔루션을 제안해 기업의 가치를 높이고, 넷제로 ESG 성과를 자동산출하는 신개념 ‘ESG 플랫폼’ 이라는 것을 장점으로 둘 수 있다"고 덧붙였다.

최근에는 이와 관련 기술협약을 맺은 한국건물에너지기술원과 ㈜서르와 ㈜사맛디 등의 첨단 기술도 적용된다.

아울러 현재 △연구과제들 △UAE 아부다비 외 글로벌 프로젝트들 △다양한 글로벌 인증들 △기업사옥, 병원 등 다양한 비주거 △초고층 건축물 △재건축, 신축 공동주택, △데이터센터, 물류센터, △공공 프로젝트 등 100여건 이상의 프로젝트가 진행 중에 있다.

고정림 대표는 "건축물의 탄소배출량 감축을 위해서는 부지선정시부터 기획단계 그리고 Design Phase (설계단계), Construction Phase (시공단계), Operation Phase (운영단계), 폐기단계에 이르기까지 모든 단계가 유기적으로 구성돼야 한다"고 설명했다.

또한 "특히 설계단계부터 ZEB 인증 의무화 및 재생에너지나 무탄소에너지원 사용에 대한 방향과 연계된 검토가 선행돼야 하고 자재시장에서도 그리고 운영단계에서도 많은 노력을 기울여야 비로소 넷제로가 구현가능하다"고 강조했다.

이에 "기후 변화대응과 관련한 다양한 환경규제와 제로에너지를 목표로 하는 국가 방향성에 맞춰 다양한 분야의 탄소배출량 감축 및 탄소중립을 구현하는 스마트그린 기술을 선도함으로써 국가 위상을 높이는 데에 최선을 다할 것이다"고 말했다. kjh123@ekn.kr

< 저작권자 ⓒ 에너지경제 무단전재 및 재배포 금지 / 대한민국 경제의 힘, 에너지경제>`,
  },
  {
    path: "/imgs/news_thumbnail_12.png",
    title: "한국권물에너지기술원 (주)아키테코그룹과 '스마트 그린 ESG 플랫폼’ 구축 업무협약 체결",
    date: "2023. 02. 21 ",
    des: `한국건물에너지기술원은 지난 2월 13일 ㈜아키테코그룹이 추진하는 건물부문 탄소중립, 즉 넷-제로(Net-Zero) 달성을 위한 플랫폼사업인 '스마트 그린 ESG 플랫폼’ 구축 업무협약을 체결했다.

    이날 아키테코그룹 사옥에서 진행된 협약식에서 (사)한국건물에너지기술원 조정훈 원장과 (주)아키테코그룹 고정림 대표는 ‘2050 온실가스 넷제로’를 목표로 지속적으로 모니터링 해저감할 수 있는 솔루션을 제안하고, ESG 성과를 자동산출하는 플랫폼으로, 근미래 탄소저감을 목표를 협력할 것을 약속했다.이번 협약을 통해 한국건물에너지기술원은 소형 건축물 대상의 보급형 에너지통합관리솔루션(xEMS)을 중대형 건축물 및 공동주택 건축물을 대상으로 하는 에너지통합솔루션으로 고도화 하며, 아키테코그룹의 ‘실시간 ESG 데이터 시스템’과 데이터를 연동하는 기능을 개발하게 된다.
    
    양사는 플랫폼의 에너지저감, 탄소저감, 친환경 기반 건물운영 사업 활성화를 위해 힘을 모으기로 했으며 한국건물에너지기술원은 이번 협약을 통하여 에너지통합관리솔루션(xEMS)의 민간 시장 확대를 꾀하고, 아키테코그룹은 이를 기반으로 ‘스마트 그린·ESG 플랫폼’의 구축을 앞당기고 신속하게 시장에 진입 할 수 있는 추진 전략을 수립하게 될 것이다.
    
    한국건물에너지기술원은 지난 2022년부터 에너지관리공단에서 운영하는 ZEB 인프라 구축 지원 사업의 수행기관으로 보급형 에너지통합관리솔루션(xEMS)을 공급해 왔으며, 2023년부터는 계측인프라 설치부터 xEMS 시스템 설치까지 수행하는 기관으로 한 단계 더 도약할 예정이다. 또한 이번 협약을 통해 향 후 공급될 에너지통합관리솔루션(xEMS)의 통합운영관리를 위한 에너지통합운영관리센터(xTOC)를 구축 운영하고, ㈜아키테코그룹과 손잡고 글로벌 시장에 진출할 수 있는 에너지통합관리솔루션(xEMS)으로 진화해 나아갈 예정이다. 
    
    출처 : 한국건물에너지기술원`,
  },
];
