import React, { useEffect, useState } from "react";
import "./styles/CiScrollEvent.scss";
const CiScrollEvent = () => {
  const [position, setPosition] = useState(0);
  function onScroll() {
    // setPosition(window.scrollY);
  }
  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);
  // console.log(position);
  return (
    <>
      <div className="ci_scroll_event_wrap">
        <div className="inner" style={{ top: `${position < 1600 ? 0 : position < 3640 ? position - 1500 : 2160}px` }}>
          <div className="left">
            <img src="/imgs/ci_scroll_event.png" alt="" />
          </div>
          <div className="right">
            <div className="logo_wrap">
              <img src="/imgs/letsgo.png" alt="let's go" />
            </div>
            <div className="ment">
              <ul>
                <li>우리는 목표를 위해 매일 전진하겠습니다.</li>
                <li>끊임없이 연구하고 노력하여 고객에게 먼저 다가가겠습니다.</li>
                <li>지구 환경과 사람이 공존과 순응 할 수 있게 함께 걸어가겠습니다.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CiScrollEvent;
