import React from "react";
import "./styles/RepresentativePerformanceResidence.scss";
const RepresentativePerformanceResidence = () => {
  const contents = [
    {
      name: `힐스테이트 검단 웰카운티\n(LH최초 제로에너지 공동주택)`,
      path: "/imgs/trackrecord_residence_02.png",
    },
    {
      name: "강릉시 저동 공동주택",
      path: "/imgs/trackrecord_residence_06.png",
    },
    {
      name: "인천 송도 6.8공구 A15BL 공동주택",
      path: "/imgs/trackrecord_residence_07.png",
    },

    {
      name: "대구 수성구 메트로팔레스 리모델링 1, 2, 3, 5단지",
      path: "/imgs/trackrecord_residence_09.png",
    },
    {
      name: "서울 서초 잠원동아 아파트 리모델링",
      path: "/imgs/trackrecord_residence_10.png",
    },
    {
      name: "평택 안중 현화지구 36BL",
      path: "/imgs/trackrecord_non_residence_03.png",
    },
    {
      name: "청주시 사직동 사모1구역 주택 재개발사업",
      path: "/imgs/trackrecord_residence_11.png",
    },
    {
      name: "빛반사 분석 - 동탄 SK 레이크원",
      path: "/imgs/trackrecord_residence_12.png",
    },
    {
      name: "의왕 삼동 한양 다세대 및 주변 가로주택 정비사업",
      path: "/imgs/trackrecord_residence_13.png",
    },
    {
      name: "춘천 효자동 699번지 주상복합",
      path: "/imgs/trackrecord_residence_14.png",
    },
    {
      name: "기류환경 평가",
      path: "/imgs/trackrecord_residence_15.png",
    },
    {
      name: "열환경 평가",
      path: "/imgs/trackrecord_residence_16.png",
    },
    {
      name: "음환경 평가",
      path: "/imgs/trackrecord_residence_17.png",
    },
  ];
  return (
    <>
      <div className="representative_performance_residence_wrap">
        <div className="title">주거부문</div>
        <div className="content">
          {contents.map((content: { name: string; path: string }, index: number) => {
            return (
              <div className="card" key={`${content.name}_${index}`}>
                <div className="img_box">
                  <img src={content.path} alt={content.name} />
                </div>
                <div className="name">{content.name}</div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default RepresentativePerformanceResidence;
