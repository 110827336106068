import React from "react";
import "./styles/CiColorSystem.scss";
const CiColorSystem = () => {
  return (
    <>
      <div className="ci_color_system_wrap">
        <h2>Color System</h2>
        <div className="bi_main_wrap">
          <div className="ment">
            컬러는 인쇄시 Pantone 색상을 기준으로 하여 RGB, CMYK 색상 적용시 명시된 색상값을 따라야 합니다. <br /> 흰색 배경에 Olivei Green와 Warm Gary를 사용하는 것이 우선이며, 경우에 따라 단색 (화이트 또는 블랙) 색상 적용이 가능합니다.
          </div>
          <div className="imgs_wrap">
            <div className="img_box">
              <img src="/imgs/ci_color_image_01.png" alt="ci_color_image" />
            </div>
            <div className="img_box">
              <img src="/imgs/ci_color_image_02.png" alt="ci_color_image" />
            </div>
            <div className="img_box">
              <img src="/imgs/ci_color_image_03.png" alt="ci_color_image" />
            </div>
          </div>
        </div>
        <div className="bi_color_wrap">
          <div className="pantone_wrap">
            <div className="text_wrap">
              <div className="code">C70 M46 Y75 K40 </div>
              <div className="name">
                Olivel Green <br /> (산, 숲)
              </div>
            </div>
            <div className="img_box">
              <img src="/imgs/ci_color_main.png" alt="main_pantone_color" />
            </div>
          </div>
          <div className="pantone_wrap">
            <div className="text_wrap">
              <div className="code">C0 M8 Y15 K30 </div>
              <div className="name">
                Warm Gray <br />
                (지구,땅)
              </div>
            </div>
            <div className="img_box">
              <img src="/imgs/ci_color_sub.png" alt="sub_pantone_color" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CiColorSystem;
