import React from "react";
import RepresentativePerformanceResidence from "./RepresentativePerformanceResidence";
import RepresentativePerformanceNonResidence from "./RepresentativePerformanceNonResidence";

const RepresentativePerformance = () => {
  return (
    <>
      <div className="representative_performance_wrap">
        <RepresentativePerformanceResidence />
        <RepresentativePerformanceNonResidence />
      </div>
    </>
  );
};

export default RepresentativePerformance;
