import React from "react";
import TitleFormat from "../../../components/TitleFormat";
import DesignCityContent from "./DesignCityContent";

const DesignCity = () => {
  const title = { text: "도시·토목·생태·스마트 경관\nNet-Zero Smart Green Design" };
  return (
    <>
      <TitleFormat text={title.text} />
      <DesignCityContent />
    </>
  );
};

export default DesignCity;
