import React from "react";
import "./styles/DesignSolutionContent.scss";
import BannerType1 from "../../../components/BannerType1";
const DesignSolutionContent = () => {
  const flow = ["넷제로 기반\nSmart Green\nDesign", "경관 관련\n분야별 업무\n맞춤형 컨설팅", "트랜드를\n선도하는 경관계획\n수립 및 제안", "혁신과 창의를\n기반으로 한 심미적\n공간 디자인"];
  const contents = [
    {
      path: "/imgs/design_solution_01.png",
      name: "New Renenwable Energy Solution Smart Green 을 위한 신재생에너지 제안",
      list: ["RE 100 / CF 100 탈탄소, 연로전지 : PEM / SOFC", "수열 시스템, 복사냉난방 시스템", "태양광 / 태양열 : 도입분석, BIPC, 자연채광 시스템", "지열 : 밀폐형, 개방형, 하이브리드"],
    },
    {
      path: "/imgs/design_solution_02.png",
      name: "Urban & Village landscape Solution 경관 계획 / 심의, 가이드 연구 및 개발",
      list: ["정책반영 경관 심의 컨설팅", "개발사업 및 건축물 경관심의", "농 / 어촌 마을 경관 계획 및 가이드", "지자체 특성 맞춤형 Smart Green Design 경관 계획 및 가이드"],
    },
    {
      path: "/imgs/design_solution_03.png",
      name: "Lighting Environment Solution 자연광과 인공광을 접모한 하이브리드 빛환경 연구",
      list: ["야간경관계획 수립 (좋은빛 위원회 심의)", "조명, 시뮬레이션 (조도, 휘도, 현휘)", "Green Smart 조명설계", "광덕트, 루버 계획", "지능형 점소등, 실시간 원격 점검"],
    },
    {
      path: "/imgs/design_solution_04.png",
      name: "Green & Innovative Solution 미래지향형 Smart Green Facade 연구 및 개발",
      list: ["공공디자인 진흥 위원회 심의 컨설팅 (Recycle + Smart fumiture 디자인 제안)", "BIPV 와 결합된 미디어 파사드 계획", "Smart Green Facade 디자인 컨설팅"],
    },
  ];
  return (
    <>
      <div className="design_solution_content_wrap">
        <BannerType1 path="/imgs/banner_design_solution.png" />
        <div className="title">솔루션 제안</div>
        <div className="content">
          <div className="des">신기술 신공법을 접모한 디자인 / 사람, 자연, 도시의 유기적 관계를 담은 혁신적인 Design Tech 를 제안합니다.</div>
          <div className="flow_wrap">
            {flow.map((text: string, index: number) => {
              return (
                <>
                  <div className="circle_wrap" key={`${text}_${index}`}>
                    <div className="circle">
                      <div className="img_box">
                        <img src="/imgs/step_circle.png" alt="circle" />
                      </div>
                      <div className="text">{text}</div>
                    </div>
                  </div>
                  <div className="triangle"></div>
                </>
              );
            })}
          </div>
          <div className="card_wrap">
            {contents.map((content: { path: string; name: string; list: string[] }, index: number) => {
              return (
                <div className="card" key={`${content.name}_${index}`}>
                  <div className="img_box">
                    <img src={content.path} alt={content.name} />
                  </div>
                  <div className="name">{content.name}</div>
                  <ul>
                    {content.list.map((text: string, index: number) => {
                      return <li key={`${text}_${index}`}>{text}</li>;
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default DesignSolutionContent;
