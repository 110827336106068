import React from "react";
import "./styles/SmartGreen.scss";
import TitleFormat from "../../../components/TitleFormat";
import NetZeroScope from "../../../components/NetZeroScope";
import DtContent from "../../../components/DtContent";

const SmartGreen = () => {
  const title = { text: "스마트그린, ESG 기술 차별성\n+DT 디지털 전환", path: "" };
  return (
    <>
      <TitleFormat text={title.text} background={title.path} />
      <div className="s_wrap">
        <div className="title_wrap">
          <div className="title">2030 / 2050 Net Zero 목표 달성 기반</div>
          <div className="des">국가 탄소감축 기여(기후변화 협약, 생물 다양성 협약, 사막화 방지 협약)</div>
        </div>
        <div className="i_wrap">
          <img src="/imgs/dt_smart_green_sample.png" alt="" />
        </div>
      </div>
      {/* <NetZeroScope />
      <DtContent /> */}
    </>
  );
};

export default SmartGreen;
