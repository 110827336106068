import React from "react";
import "./styles/BtnViewMore.scss";
type Props = {
  id?: string;
  onClick: any;
};
const BtnViewMore = ({ id, onClick }: Props) => {
  return (
    <>
      <button id={id} className="btn_view_more" onClick={onClick}>
        VIEW MORE
        <span style={{ marginLeft: "7px" }}>
          <img src="/imgs/arrow_type_01.png" alt="arrow" />
        </span>
      </button>
    </>
  );
};

export default BtnViewMore;
