import React from "react";
import TitleFormat from "../../../components/TitleFormat";
import CiScrollEvent from "./CiScrollEvent";
import CiSignature from "../../../components/CiSignature";
import CiColorSystem from "../../../components/CiColorSystem";
import CiDownload from "../../../components/CiDownload";

const Ci = () => {
  const title = `The Smart Green ESG\nPlatform Leader`;
  const formatCi = {
    title: "대표 CI",
    ment: `아키테코그룹의 CI 모티브는 아키테코의 이니셜 A를 거대한 산의 모양으로 형상화하여 스마트 그린
    플랫폼에서 가장 영향력있는 글로벌 기업으로 우뚝 솟겠다는 의미로 디자인되었으며, 
    또한 웜그레이의 원형은 기업과 도시, 그리고 더크게 지구를 나타내며 여기에 아키테코그룹이 감싸 안아 
    스마트 그린 플랫폼 리더로서 의지의 표명입니다.`,
    img_path_01: "/imgs/ci_main_1440.png",
    img_path_02: "/imgs/ci_black_470.png",
    img_path_03: "/imgs/ci_white_470.png",
    img_path_04: "/imgs/ci_main_470.png",
    img_path_05: "/imgs/ci_interval_type1.png",
    img_path_06: "/imgs/ci_interval_type2.png",
  };
  const formatBi = {
    title: "대표 BI",
    ment: `GO (지오)는 아키테코그룹의 브랜드명으로서
    지구 환경과 사람이 공존하여 함께 걸어가는 의미의 지구를 나타내는 O 와
    함께 전진한다는 의미의 영문 GO를
    ${(<br />)}형성화하여 시각적으로 표현하였습니다.`,
    img_path_01: "/imgs/bi_main_1440.png",
    img_path_02: "/imgs/bi_black_470.png",
    img_path_03: "/imgs/bi_white_470.png",
    img_path_04: "/imgs/bi_main_470.png",
    img_path_05: "/imgs/bi_interval_type1.png",
    img_path_06: "/imgs/bi_interval_type2.png",
  };
  return (
    <>
      <TitleFormat text={title} />
      <CiScrollEvent />
      <h1 style={{ margin: "15% 15% 0px 15%", fontSize: 60, fontWeight: "bold", lineHeight: "90px", borderBottom: "1px solid gray" }}>Signature</h1>
      <CiSignature
        title={formatCi.title}
        ment={formatCi.ment}
        img_path_01={formatCi.img_path_01}
        img_path_02={formatCi.img_path_02}
        img_path_03={formatCi.img_path_03}
        img_path_04={formatCi.img_path_04}
        img_path_05={formatCi.img_path_05}
        img_path_06={formatCi.img_path_06}
      />
      <CiSignature
        title={formatBi.title}
        ment={formatBi.ment}
        img_path_01={formatBi.img_path_01}
        img_path_02={formatBi.img_path_02}
        img_path_03={formatBi.img_path_03}
        img_path_04={formatBi.img_path_04}
        img_path_05={formatBi.img_path_05}
        img_path_06={formatBi.img_path_06}
      />
      <CiColorSystem />
      <CiDownload />
    </>
  );
};

export default Ci;
