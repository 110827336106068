import React from "react";
import "./styles/SaaSTestBedOverView.scss";
import BannerType1 from "./BannerType1";
import path from "path";
const SaaSTestBedOverView = () => {
  const content = [
    {
      path: "/imgs/saas_space_info_01.png",
      title: "GALLERY 1F",
      list: [
        { ul: "AI /  디지털트윈 기반 SIT 연구소 시스템", li: ["저에너지 공조설비 / 환기시스템", "저탄소 건축자재등"] },
        { ul: "테스트베드 적용 에너지 아이템", li: ["바닥재 (1층)"] },
      ],
    },
    {
      path: "/imgs/saas_space_info_02.png",
      title: "Biz Center 2F / 3F",
      list: [
        { ul: "AI /  디지털트윈 기반 SIT 연구소 시스템", li: ["메인 서버 통합제어 컨트롤러"] },
        { ul: "테스트베드 적용 에너지 아이템", li: ["환기시스템 (전층)", "신기술 무기질도료 (전층)"] },
      ],
    },
    {
      path: "/imgs/saas_space_info_03.png",
      title: "GeO D&T / R&D Center 4F / 5F",
      list: [
        { ul: "AI /  디지털트윈 기반 SIT 연구소 시스템", li: ["모니터링시스템 CCTV / 센서 / 카메라"] },
        { ul: "테스트베드 적용 에너지 아이템", li: ["조명 LED, 형광등, 디머, 경관조명", "세라믹 단열필름 (GO필름)", "대리석"] },
      ],
    },
    {
      path: "/imgs/saas_space_info_04.png",
      title: "Academy Hall 6F",
      list: [
        { ul: "AI /  디지털트윈 기반 SIT 연구소 시스템", li: ["첨단 방송장비 디지털 오디오 믹서기"] },
        { ul: "테스트베드 적용 에너지 아이템", li: ["공기청정 기능 EMP / BEMS / 모니터링시스템"] },
      ],
    },
    {
      path: "/imgs/saas_space_info_05.png",
      title: "ROOFTOP 7F",
      list: [
        { ul: "AI /  디지털트윈 기반 SIT 연구소 시스템", li: ["첨단 방송장비 디지털 오디오 믹서기"] },
        { ul: "테스트베드 적용 에너지 아이템", li: ["인공잔디"] },
      ],
    },
  ];
  return (
    <>
      <div className="saas_test_bed_overview">
        <BannerType1 path="/imgs/banner_testbed_overview.png" />
        <div className="title">건축 신공법, EPD 신기술 자재, 재생에너지 시스템으로 로드맵을 제시합니다</div>
        <div className="overview">
          <img src="/imgs/testbed_overview.png" alt="testbed_overview" />
        </div>
        <div className="floor_wrap">
          <div className="title">층별 소개</div>
          <div className="upper">
            <div className="img_box">
              <img src="/imgs/testbed_floor_upper_01.png" alt="floor" />
            </div>
            <div className="img_box">
              <img src="/imgs/testbed_floor_upper_02.png" alt="floor" />
            </div>
          </div>
          <div className="under">
            {content.map((data: { path: string; title: string; list: { ul: string; li: string[] }[] }, index: number) => {
              return (
                <div className="card" key={`${data.title}_${index}`}>
                  <div className="img_box">
                    <img src={data.path} alt={data.title} />
                  </div>
                  <div className="name">{data.title}</div>
                  <div className="list_wrap">
                    {data.list.map((list: { ul: string; li: string[] }, index2: number) => {
                      return (
                        <ul key={`${list.ul}_${index2}`}>
                          <div className="text">{list.ul}</div>
                          {list.li.map((text: string, index3: number) => {
                            return <li key={`${text}_${index3}`}>{text}</li>;
                          })}
                        </ul>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default SaaSTestBedOverView;
