import React from "react";
import "./styles/TitleFormat.scss";
type Props = {
  text: string;
  textWidth?: number;
  background?: string;
};
const TitleFormat = ({ text, textWidth, background }: Props) => {
  return (
    <>
      <div className="title_format_wrap" style={{ backgroundImage: `url(${background})` }}>
        <h2 style={{ width: textWidth }}>{text}</h2>
      </div>
    </>
  );
};

export default TitleFormat;
