import React from "react";
import "./styles/ItCarbonNeutralCity.scss";

const ItCarbonNeutralCity = () => {
  const content_01 = ["사우디\n네옴시티", "물안심도시\n: K Water", "인도네시아\n신수도 이전\n스마트 시티 :\n행복청", "안산시\n탄소중립도시", "제천시\n스마트 산단", "하와이\n리조트"];
  const content_02 = ["도시열섬분석(AI 기반\n도시 냉각기술 개발)", "도시 물순환 회복\n저영향 개발", "환경영향평가\n교육환경평가", "넷제로 시티\n탄소중립도시 인증", "플랫폼을 통한\n모니터링 및 관리", "탄소배출권 거래\nREC"];
  const contnet_03 = [
    { name: "1. 인벤토리 구축 및 업무 범위", text: "온실가스 배출량 데이터수집, 감축 목표 검토, 계획 수립을 위한 업무 범위 정의" },
    { name: "2. 포트폴리오 분류", text: "포트폴리오 평가를 위한 대표적인 사업장 / 공정 / 건물 선정" },
    { name: "3. 배출량 감축 조치 평가", text: "온실가스 배출량감축 진단 또는 평가를 수행하고 온실가스 배출량 감축 조치를 선정" },
    { name: "4. 감축시나리오 개발", text: "온실가스 감축 조치와 전략을 적용하여 온실가스 배출량 감축 시나리오를 개발" },
    { name: "5. 배출량 감축 계획수립", text: "시나리오를 평가하고, 경로를 선정하고, 자금조달 및 수행 단계를 정의하고, 계획을 승인" },
    { name: "6. 이행", text: "" },
  ];
  return (
    <>
      <div className="it_carbon_neutral_city_wrap">
        <div className="city_wrap">
          <h2>
            탄소중립 도시 <br /> Net-Zero City / Town
          </h2>
          <div className="content">
            <div className="des">탄소중립도시란 도시를 이루는 공간, 교통, 기반시설, 공원 및 녹지 등 효율이고 친환경적인 구성을 통해 탄소배출량 감소와 더불어 탄소 흡수량을 늘려 줌에 따라 순탄소 배출량 0을 만드는 도시를 말합니다.</div>
            <div className="circle_wrap">
              {content_01.map((text: string, index: number) => {
                return (
                  <div className="circle" key={`${text}_${index}`}>
                    <div className="img_box">
                      <img src="/imgs/step_circle.png" alt="circle" />
                    </div>
                    <span>{text}</span>
                  </div>
                );
              })}
            </div>
            <div className="tech_wrap">
              {content_02.map((text: string, index: number) => {
                return (
                  <div className="box" key={`${text}_${index}`}>
                    {text}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="erp_wrap">
          <h2>
            ERP (배출감축플랜) <br /> Framework
          </h2>
          <div className="content">
            <img className="cycle" src="/imgs/erp_framework_01.png" alt="erp_framework_img" />
            <img className="cycle_row" src="/imgs/erp_framework_01_.png" alt="erp_framework_img" />
            <div className="step_wrap">
              <img className="step_img" src="/imgs/erp_framework_02.png" alt="erp_framework_img" />
              <ul className="step_list">
                {contnet_03.map((content: { name: string; text: string }, index: number) => {
                  return (
                    <li key={`${content.name}_${index}`}>
                      <div className="step">
                        <div className="name">{content.name}</div>
                        {content.text !== "" ? (
                          <ul>
                            <li className="text">{content.text}</li>
                          </ul>
                        ) : null}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ItCarbonNeutralCity;
