import React from "react";
import "./styles/CiDownload.scss";

const CiDownload = () => {
  return (
    <>
      <div className="ci_download_wrap">
        <h2>CI 다운로드</h2>
        <div className="inner">
          <div className="content">
            <div className="img_box">
              <img src="/imgs/ci_architecogroup.png" alt="ci_architecogroup" />
            </div>
            <div className="btn_wrap">
              <button>
                <img src="/imgs/icon_download.png" alt="download_icon" />
                <span>JPG 다운로드</span>
              </button>
              <button>
                <img src="/imgs/icon_download.png" alt="download_icon" />
                <span>PNG 다운로드</span>
              </button>
            </div>
          </div>
          <div className="content">
            <div className="img_box">
              <img src="/imgs/ci_geodnt.png" alt="ci_geodnt" />
            </div>
            <div className="btn_wrap">
              <button>
                <img src="/imgs/icon_download.png" alt="download_icon" />
                <span>JPG 다운로드</span>
              </button>
              <button>
                <img src="/imgs/icon_download.png" alt="download_icon" />
                <span>PNG 다운로드</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CiDownload;
