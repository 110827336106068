import React from "react";
import "./styles/HistoryContent.scss";
// aboutus_history_content_01.png;
const HistoryContent = () => {
  return (
    <>
      <div id="history_content_wrap">
        <div className="left">
          <div className="img_wrap">
            <div className="img_box">
              <img src="/imgs/aboutus_history_content_01.png" alt="office" />
            </div>
            <div className="img_box">
              <img src="/imgs/aboutus_history_content_02.png" alt="office" />
            </div>
            <div className="img_box">
              <img src="/imgs/aboutus_history_content_03.png" alt="office" />
            </div>
            <div className="img_box">
              <img src="/imgs/aboutus_history_content_04.png" alt="office" />
            </div>
            <div className="img_box">
              <img src="/imgs/aboutus_history_content_05.png" alt="office" />
            </div>
          </div>
        </div>
        <div className="right">
          <img src="/imgs/history_content_sample.png" alt="" />
        </div>
      </div>
    </>
  );
};

export default HistoryContent;
