import React from "react";
import "./styles/TitleFormat2.scss";
type Props = {
  text: string;
};
const TitleFormat2 = ({ text }: Props) => {
  return (
    <>
      <div className="title_format_2_wrap">
        <h2>{text}</h2>
      </div>
    </>
  );
};

export default TitleFormat2;
