import React from "react";
import "./styles/TestBed.scss";
import TitleFormat from "../components/TitleFormat";
import SaaSTestBedOverView from "../components/SaaSTestBedOverView";
import SaaSSpaceInfo from "../components/SaaSSpaceInfo";
const TestBed = () => {
  const title = { text: "테스트베드로 운영중인 본사\n사옥 제주지사를 통해 단계별\nNet Zero를 구현 합니다", path: "" };

  return (
    <>
      <div className="test_bed_wrap">
        <TitleFormat text={title.text} />
        <SaaSTestBedOverView />
        {/* <SaaSSpaceInfo /> */}
      </div>
    </>
  );
};

export default TestBed;
