import React, { useState } from "react";
import PageTemplate from "../components/PageTemplate";
import TotalEco from "./TotalEco";
import ItEnvironment from "./ItEnvironment";

const Consulting = () => {
  const [tap, setTap] = useState(0);
  return (
    <>
      <PageTemplate title="토탈친환경 계획" menuList={["토탈 친환경 컨설팅", "환경성능 컨설팅"]} tap={tap} setTap={setTap} backgroundImage="/imgs/bg_digitaltwin" />
      <div className="consulting_wrap" style={{ maxWidth: "1920px", width: "100%" }}>
        {(() => {
          switch (tap) {
            case 0:
              return <TotalEco />;
            case 1:
              return <ItEnvironment />;
            default:
              return <TotalEco />;
          }
        })()}
      </div>
    </>
  );
};

export default Consulting;
