import React from "react";
import CeoContent from "./CeoContent";
import CeoOrganizationChart from "./CeoOrganizationChart";

const Ceo = () => {
  return (
    <>
      <CeoContent />
      <CeoOrganizationChart />
    </>
  );
};

export default Ceo;
