import React from "react";
import AcademyContent from "../../components/AcademyContent";
import TitleFormat from "../../components/TitleFormat";

const Academy = () => {
  const title = { text: "아키테코그룹은 아카데미를 통한\n끊임없는 발전을 추구합니다" };

  return (
    <>
      <TitleFormat text={title.text} />
      <AcademyContent />
    </>
  );
};

export default Academy;
