import React from "react";
import "./styles/ItEnvironmentContent.scss";
import BannerType1 from "./BannerType1";
const ItEnvironmentContent = () => {
  const contents = [
    { path: "/imgs/it_environment_01.png", name: "기류환경 평가 - 건축물 외부환경 평가", list: ["바람길, 빌딩풍, 초고층풍환경, 터널환기", "도심지 열섬, 백연현상, 폭염저감", "Ice Snow Effect, 화재, 미세먼지"] },
    { path: "/imgs/it_environment_02.png", name: "열환경 평가 - 건축물 내외부 온열환경 평가", list: ["냉난방 시스템 성능, 일사영향 검토", "PMV, IAQ, 취기 및 오염물질 확산", "열교 / 결로, 클린룸, 데이터센터"] },
    { path: "/imgs/it_environment_03.png", name: "빛환경 평가 - 건축물 내외부 빛환경 평가", list: ["일조, 일사영향 분석, 루버디자인", "자연채광, 빛반사(현휘) 영향분석", "인공조명 조도 분석, 미디어파사드"] },
    { path: "/imgs/it_environment_04.png", name: "음환경 평가 - 도로소음 및 실내음향 평가", list: ["도로교통 소음 평가", "층간, 세대간 소음영향 평가", "실내음향 검토"] },
  ];
  return (
    <>
      <div className="it_environment_content_wrap">
        <BannerType1 path="/imgs/banner_it_environment.png" />
        <div className="title">
          건축물 환경성능 분석 <br />및 컨설팅
        </div>
        <div className="contents">
          <div className="des"> 미기후, 부지특성, 건축물 종류 및 형태 등을 고려한 환경 성능 평가 및 컨설팅을 수행하여 재실자의 건강과 쾌적성을 증대시키고 환경부하를 최소화여 Pre - Passive를 구현합니다.</div>
          <div className="card_wrap">
            {contents.map((content: { path: string; name: string; list: string[] }, index: number) => {
              return (
                <div className="card" key={`${content.name}_${index}`}>
                  <div className="img_box">
                    <img src={content.path} alt={content.name} />
                  </div>
                  <div className="name">{content.name}</div>
                  <ul>
                    {content.list.map((text: string, index: number) => {
                      return <li key={`${text}_${index}`}>{text}</li>;
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ItEnvironmentContent;
