import React from "react";
import "./styles/EnergyStep.scss";
import BannerType1 from "./BannerType1";
const EnergyStep = () => {
  const contents = [
    { name: "Pre - Passive & Passive Design 에너지요구량저감", des: "햇빛,  바람 등 자연에 순응하는 건물의 최적 배치계획,단열성능향상, 융복합 입면디자인 등을 통한 건물의 부하량 저감으로 설비 용량 최적화" },
    { name: "Active Design 에너지 소요량 절감", des: "고효율 기자재설비, HAVC시스템 최적화, 용도 특성을 고려한 조명 설계 등을 통한 화석연료 최소화" },
    { name: "Renewable Energy 에너지 자립율 향상", des: "태양광 (PV / BIPV), 지열 (수직형 / 개방형) 연료전지 (PEMFC / SOFC), 집광채광, 광덕트, 소형 풍력 등의 국내 최고 업체 연계" },
    { name: "Building Energy Management System 에너지 관리효율 최적화", des: "BEMS, 전자식 원격검침 계량기를 통한 실시간 에너지사용량 확인 및 최적 가동방안 등에 대한 솔루션 제공" },
  ];
  return (
    <>
      <div className="energy_step_wrap">
        <BannerType1 path="/imgs/banner_netzero_overview.png" />
        <div className="title">에너지 관리 Step</div>
        <div className="contents">
          {contents.map((content: { name: string; des: string }, index: number) => {
            return (
              <div className="content" key={`${content.name}_${index}`}>
                <div className="left">
                  <div className="img_box">
                    <img src="/imgs/step_circle.png" alt="circle" />
                  </div>
                  <span>Step {index + 1}</span>
                </div>
                <div className="right">
                  <div className="name">{content.name}</div>
                  <div className="des">{content.des}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default EnergyStep;
