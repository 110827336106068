import React from "react";
import "./styles/BusinessInfo.scss";
import { useNavigate } from "react-router-dom";
import BtnViewMore from "../../components/BtnViewMore";
const BusinessInfo = () => {
  const navigate = useNavigate();
  const handleBtn = (e: any) => {
    const { to, tap } = contents[Number(e.currentTarget.id)];
    navigate(to, { state: { tap } });
  };
  const contents = [
    {
      to: "/digitaltwin",
      tap: 1,
      path: "/imgs/bg_home_business_info_04.png",
      name: "DT",
      des: "탄소/온실가스/에너지 관리/탈탄소 성과모니터링/BEMS/ESG 플랫폼/AIoT 스마트 실내공기질 모니터링 시스템(지오AIR) 등 다양한 IT 기술을 연결하는 토탈 SIT",
    },

    {
      to: "/globalit",
      tap: 0,
      path: "/imgs/bg_home_business_info_02.png",
      name: "R&D",
      des: "연구와 실무의 체계적인 결합 및 풍부한 실적을 바탕으로 전문적인 탈탄소화 전략/ESG 통합솔루션/LCA 생애주기 전과정에 걸쳐 Scope3 탄소배출량 저감방안과 제공합니다.",
    },
    {
      to: "/design",
      tap: 1,
      path: "/imgs/bg_home_business_info_03.png",
      name: "Design",
      des: "다양한 프로젝트 경험, 풍부한 전문인력, 신기술 네트워크 등을 기반으로 탈탄소 건축문화를 보급하는 도시/건축물경관 전반에 걸친 Smart Green 인프라를 구축해 나아갑니다.",
    },
    {
      to: "/consulting",
      tap: 0,
      path: "/imgs/bg_home_business_info_01.png",
      name: "Technology",
      des: "기획단계에서 준공 및 운영단계에 걸친 단계별 최적의 탄소중립 프로세스를 적용하여 건축물의 환경성능을 향상시키고 각종 인센티브를 제공합니다.",
    },
  ];
  return (
    <>
      <div id="business_info_wrap">
        <div className="title_wrap">
          <div className="title">사업 파트 소개</div>
        </div>
        <div className="card_wrap">
          {contents.map((content: { path: string; name: string; des: string }, index: number) => {
            return (
              <div id={String(index)} className="card" key={`${content.name}_${index}`}>
                <div className="left">
                  <div className="img_box">
                    <img src={content.path} alt={content.name} />
                  </div>
                </div>
                <div className="right">
                  <div className="name">{content.name}</div>
                  <div className="des">{content.des}</div>
                  <BtnViewMore id={String(index)} onClick={handleBtn} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default BusinessInfo;
