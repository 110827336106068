import React, { useEffect, useRef, useState } from "react";
import "./styles/IntroductionVideo.scss";
import { ReactComponent as Play } from "../../assets/video_play.svg";
import { ReactComponent as Pause } from "../../assets/video_pause.svg";
const IntroductionVideo = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [nowPlay, setNowPlay] = useState(false);
  const handleVideo = (e: any) => {
    if (e.currentTarget.id === "btn_pp") {
      if (videoRef.current?.paused) {
        videoRef.current?.play();
        setNowPlay(true);
      } else {
        videoRef.current?.pause();
        setNowPlay(false);
      }
    } else {
      if (videoRef.current?.paused) {
        setNowPlay(true);
      } else {
        setNowPlay(false);
      }
    }
  };

  return (
    <>
      <div className="introduction_video_wrap">
        <div className="title_wrap">
          <h2>소개 영상</h2>
        </div>
        <div className="video_wrap">
          <video controls poster="/imgs/video_thumb.png" ref={videoRef} onClick={handleVideo}>
            <source src="/video/video_01.mp4" type="video/mp4" />
          </video>
          <button id="btn_pp" className={nowPlay ? "play" : "pause"} onClick={handleVideo}>
            {nowPlay ? <Pause className="pause" style={{ visibility: "hidden" }} /> : <Play className="play" />}
          </button>
        </div>
      </div>
    </>
  );
};

export default IntroductionVideo;
