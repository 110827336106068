import React, { useEffect, useState } from "react";
import "./styles/DesignGalleryContent.scss";
import CustomSlider from "../../../components/CustomSlider";

const DesignGalleryContent = () => {
  const [content, setContent] = useState<string[][]>([]);
  const list = [
    "/imgs/gallery_content_01.png",
    "/imgs/gallery_content_02.png",
    "/imgs/gallery_content_03.png",
    "/imgs/gallery_content_04.png",
    "/imgs/gallery_content_05.png",
    "/imgs/gallery_content_06.png",
    "/imgs/gallery_content_07.png",
    "/imgs/gallery_content_08.png",
    "/imgs/gallery_content_09.png",
    "/imgs/gallery_content_10.png",
    "/imgs/gallery_content_11.png",
    "/imgs/gallery_content_12.png",
    "/imgs/gallery_content_13.png",
    "/imgs/gallery_content_14.png",
    "/imgs/gallery_content_15.png",
    "/imgs/gallery_content_16.png",
    "/imgs/gallery_content_17.png",
    "/imgs/gallery_content_18.png",
    "/imgs/gallery_content_19.png",
    "/imgs/gallery_content_20.png",
    "/imgs/gallery_content_21.png",
    "/imgs/gallery_content_22.png",
    "/imgs/gallery_content_23.png",
    "/imgs/gallery_content_24.png",
    "/imgs/gallery_content_25.png",
    "/imgs/gallery_content_26.png",
    "/imgs/gallery_content_27.png",
  ];
  useEffect(() => {
    const count = 15;
    const result = [];
    for (let i = 0; i < list.length / count; i++) {
      result.push(list.slice(i * count, i * count + count));
    }
    setContent(result);
  }, []);
  return (
    <>
      <div id="design_gallery_content">
        <div className="title">게시물</div>
        <div className="content">
          <CustomSlider
            setting={{
              infinite: true,
              speed: 500,
              slidesToShow: 1,
              slidesToScroll: 1,
              autoplay: false,
              autoplaySpeed: 3000,
              arrows: content.length > 1 ? true : false,
              draggable: false,
            }}
            navigation={{ pagingType: "type_01", color: "#111111", position: { bottom: "-100px", left: "50%", transform: "translate(-50%, -50%)" } }}
          >
            {content.map((pathArray: string[], index: number) => {
              return (
                <div className="card_wrap" key={`${pathArray[index]}_${index}`}>
                  {pathArray.map((path: string, index2: number) => {
                    return (
                      <div className="card" key={`${path}_${index2}`}>
                        <div className="img_box">
                          <img src={`${path}`} alt={`${path}`} />
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </CustomSlider>
        </div>
      </div>
    </>
  );
};

export default DesignGalleryContent;
