import React from "react";
import "./styles/TrackRecordClientContent.scss";
const TrackRecordClientContent = () => {
  const clientArray = [
    "/imgs/client_01.png",
    "/imgs/client_02.png",
    "/imgs/client_03.png",
    "/imgs/client_04.png",
    "/imgs/client_05.png",
    "/imgs/client_06.png",
    "/imgs/client_07.png",
    "/imgs/client_08.png",
    "/imgs/client_09.png",
    "/imgs/client_10.png",
    "/imgs/client_11.png",
    "/imgs/client_12.png",
    "/imgs/client_13.png",
    "/imgs/client_14.png",
    "/imgs/client_15.png",
    "/imgs/client_16.png",
    "/imgs/client_17.png",
    "/imgs/client_18.png",
    "/imgs/client_19.png",
    "/imgs/client_20.png",
    "/imgs/client_21.png",
    "/imgs/client_22.png",
    "/imgs/client_23.png",
    "/imgs/client_24.png",
    "/imgs/client_25.png",
    "/imgs/client_26.png",
    "/imgs/client_27.png",
    "/imgs/client_28.png",
    "/imgs/client_29.png",
    "/imgs/client_30.png",
    "/imgs/client_31.png",
    "/imgs/client_32.png",
    "/imgs/client_33.png",
    "/imgs/client_34.png",
    "/imgs/client_35.png",
    "/imgs/client_36.png",
  ];
  const companyList = [
    "삼성물산",
    "현대건설",
    "롯데건설",
    "한화건설",
    "SK ecoplant",
    "우미건설",
    "일성건설",
    "삼우종합건축사사무소",
    "해안종합건축사사무소",
    "정림건축사사무소",
    "간삼건축종합설계사사무소",
    "디에이그룹 엔지니어링종합건축사사무소",
    "에이앤유디자인그룹건축사사무소",
    "에이에이아키그룹건축사사무소",
    "종합설계사무소 건원",
    "창조종합건축사사무소",
    "나우동인건축사사무소",
    "종합건축사사무소 동일건축",
    "선진엔지니어링건축사사무소",
    "이가종합건축사사무소",
    "상지엔지니어링건축사사무소",
    "유선엔지니어링건축사사무소",
    "두가씨앤씨종합건축사사무소",
    "종합건축사사무소 디자인캠프문박디엠피",
    "에이치원종합건축사사무소",
    "희림종합건축사사무소",
    "행림종합건축사사무소",
    "㈜이산",
    "라온아크테크 건축사사무소",
    "한원포럼건축사사무소",
  ];
  const schoolList = ["이화여자대 / 고려대 / 숭실대 / 중앙대", "부천대 / 충남대 / 강원대 / 한경국립대", "서울대학교 / 군산대"];
  return (
    <>
      <div id="trackRecord_client_content">
        <div className="left">
          {clientArray.map((path: string, index) => {
            return (
              <div className="img_box" key={index}>
                <img src={path} alt="client" />
              </div>
            );
          })}
        </div>
        <div className="right">
          <div className="list_wrap company_wrap">
            <div className="title">기업</div>
            <ul>
              {companyList.map((text: string, index: number) => {
                return (
                  <li className="list" key={`${text}_${index}`}>
                    {text}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="list_wrap school_wrap">
            <div className="title">학교</div>
            <ul>
              {schoolList.map((text: string, index: number) => {
                return (
                  <li className="list" key={`${text}_${index}`}>
                    {text}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrackRecordClientContent;
